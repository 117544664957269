import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { getParkingStructuresRequest } from "store/actions";

const ParkingSelector = ({
  getParkingStructuresRequest,
  parkingStructures,
  onChangeHandler,
  loading,
  defaultSelected = [],
  role,

  isMulti = false,
  placeholder = "Select...",
  ...props
}) => {
  const { user } = useSelector(({ Login }) => Login);
  const [filter, setFilter] = useState({
    orderBy: "date_created_utc",
    order: -1,
    page: 0,
    limit: 30,
    search: "",
    owner_id: user?._id,
  });
  const [selected, setSelected] = useState(defaultSelected);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const collectionOptions = (parkingStructures) =>
    (parkingStructures || []).map((item) => ({
      value: item?._id,
      label: item?.building_name,
    }));

  const defaultOptions = (parkingStructures || []).map((item) => ({
    value: item?._id,
    label: item?.building_name,
  }));

  useEffect(() => {
    if (user?._id) {
      getParkingStructuresRequest({
        ...filter,
        owner_id: user?._id,
      });
    }
  }, [JSON.stringify(filter, user)]);

  const loadOptions = (inputValue, callback) => {
    getParkingStructuresRequest(
      { ...filter, search: inputValue },
      ({ data }) => {
        const options = collectionOptions(data);
        setTimeout(() => {
          callback(options);
        }, 1000);
        setFilter({ ...filter, search: inputValue });
      }
    );
  };

  const onChangeSelector = (data) => {
    if (isMulti) {
      if (data?.length) {
        // const ids= [];
        // data.forEach((a)=>{
        //     ids.push(a.value);
        // },[])
        // console.log("allIds",ids);
        onChangeHandler && onChangeHandler(data);
        setSelected(data);
      } else {
        onChangeHandler && onChangeHandler([]);
        setSelected([]);
      }
    } else {
      onChangeHandler(data);
    }
  };

  return (
    // <Select options={userOptions} />
    <AsyncSelect
      isMulti={isMulti}
      placeholder={placeholder}
      value={selected}
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      onChange={(data) => onChangeSelector(data)}
      // onInputChange={(value)=>onInputChange(value)}
    />
  );
};

const mapStateToProps = ({ Parkings, Login }) => ({
  loading: Parkings.loading,
  parkingStructures: Parkings.parkingStructures,
  role: Login?.user?.role || "",
  user: Login?.user,
});

const mapDispatchToProps = (dispatch) => ({
  getParkingStructuresRequest: (data, callback) => {
    dispatch(getParkingStructuresRequest(data, callback));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(ParkingSelector))
);
