import React from 'react'
import { Container } from 'reactstrap'

import Welcomegreet from 'components/Welcomegreet'
import Welcomecards from 'components/Welcomecards'
import Editprofile from './editprofile'

function Manageprofile() {
  return (
    <React.Fragment>
    <div className="page-content">
         <Container fluid>
              <Welcomegreet/>
              <Welcomecards/>
              <Editprofile/>
         </Container>
    </div>
    </React.Fragment>
  )
}

export default Manageprofile