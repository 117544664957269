import { call, put, takeLatest } from "redux-saga/effects";
import toastr from "toastr";

// Login Redux States
import { GET_BOOKINGS_DETAILS_SUCCESS, GET_BOOKINGS_REQUEST, GET_BOOKING_DETAILS_REQUEST, GET_OWNER_BOOKINGS_REQUEST, GET_USER_BOOKINGS_REQUEST } from "./actionTypes";
import { getBookingDetailsFail, getBookingDetailsSuccess, getBookingsFail, getBookingsSuccess, getOwnerBookingsFail, getOwnerBookingsSuccess, getUserBookingsFail, getUserBookingsSuccess } from "./actions";

import { getBookingDetailsApi, getBookingsApi, getOwnerBookingsApi, getUserBookingsApi } from "helpers/backend_helper";

function* getUserBookingsReq({ payload: {data, callback } }) {
  try {
    const response = yield call(getUserBookingsApi,data)

    if (response.status === "failure") {
        toastr.error(response.message);
      return yield put(getUserBookingsFail(response.message));
    }
    yield put(getUserBookingsSuccess(response))
    callback && callback(response);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(getUserBookingsFail(error.message))
  }
}

function* getOwnerBookingsReq({ payload: {data, callback } }) {
  try {
    const response = yield call(getOwnerBookingsApi,data)

    if (response.status === "failure") {
        toastr.error(response.message);
      return yield put(getOwnerBookingsFail(response.message));
    }
    yield put(getOwnerBookingsSuccess(response))
    callback && callback(response);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(getOwnerBookingsFail(error.message))
  }
}


function* getBookingsReq({ payload: {data, callback } }) {
    try {
      const response = yield call(getBookingsApi,data)
  
      if (response.status === "failure") {
          toastr.error(response.message);
        return yield put(getBookingsFail(response.message));
      }
      yield put(getBookingsSuccess(response))
      callback && callback(response);
    } catch (error) {
      toastr.error("Something went wrong!");
      yield put(getBookingsFail(error.message))
    }
  }
  


function* getBookingDetailsReq({ payload: {data, callback } }) {
  try {
    const response = yield call(getBookingDetailsApi,data)

    if (response.status === "failure") {
        toastr.error(response.message);
      return yield put(getBookingDetailsFail(response.message));
    }
    yield put(getBookingDetailsSuccess(response))
    callback && callback(response);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(getBookingDetailsFail(error.message))
  }
}


function* supportSaga() {
  yield takeLatest(GET_USER_BOOKINGS_REQUEST, getUserBookingsReq)
  yield takeLatest(GET_OWNER_BOOKINGS_REQUEST, getOwnerBookingsReq)
  yield takeLatest(GET_BOOKINGS_REQUEST, getBookingsReq)
  yield takeLatest(GET_BOOKING_DETAILS_REQUEST, getBookingDetailsReq);
}

export default supportSaga;
