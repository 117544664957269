import React,{useEffect,useState} from "react";
import { COUNTRY_OPTIONS } from "helpers/contants";
import Select from 'react-select';



const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#000',
      borderRadious : 8,
      minHeight: '40px',
      height: '40px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '40px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };

export const CountryCodeSelector = ({selectorName="countryCode",selectedCountryCode,onChangeHandler,extraStyleClasses}) =>{
     const [selectedValue,setSelectedValue] = useState(null);
   const countyOptions = (COUNTRY_OPTIONS ||[]).map((item)=>({label : `${item.countryCode} (${item.label})`, value : item.countryCode}))

   useEffect(()=>{
    const selectedValue = (countyOptions || []).find((item)=> item.value === selectedCountryCode);
    setSelectedValue(selectedValue); 
   },[selectedCountryCode]);


const onSelectCountryCode = (data) =>{
     setSelectedValue(data);
     onChangeHandler({target:{name:selectorName,value:data?.value}})
}
   
    return(
        <Select
            className={`${extraStyleClasses} `}
            value={selectedValue}
            onChange={(data)=>onSelectCountryCode(data)}
            options={countyOptions}
            styles={customStyles}
      />
    );
}

