import React,{useState} from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner,
} from "reactstrap";


// images
import Contactusimg from "../../../src/assets/images/contactus.png"
import { withTranslation } from "react-i18next";
import {addContactUsRequest} from "store/actions";
import { useDispatch, useSelector } from "react-redux";

function Contactusform(props) {
 const init = {
  name : "",
  email : "",
  phone : "",
  message : ""
}
  const [fields,setFields] = useState(init);
  const {loading} = useSelector(({Support})=> Support)
  const dispatch = useDispatch();
  
  const onFieldsChange = (e) => {
   const {name,value} = e.target;
   setFields((prev)=>({...prev,[name]:value}));
  }

  const onHandleSubmit = (e) =>{
    e.preventDefault();
    dispatch(addContactUsRequest(fields,()=>{
      setFields(init);
    }));
  }

  return (
    <section className="contact_us mt-5">
      <Container fluid>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className="contact_us_head">
              <h2>{props.t("contact_us")}</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                euturpis molestie, dictum est a,.
              </p>
            </div>
            <div className="contactform_here">
              <Form className="contact_form" onSubmit={onHandleSubmit}>
                <FormGroup>
                  <Label for="exampleEmail"> {props.t("name")}</Label>
                  <Input
                    type="text"
                    name="name"
                    id="exampleEmail"
                    placeholder=""
                    value={fields.name}
                    onChange={onFieldsChange}
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="exampleEmail"> {props.t("email")}</Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder=""
                    value={fields.email}
                    onChange={onFieldsChange}
                    required

                  />
                </FormGroup>

                <FormGroup>
                  <Label for="exampleEmail"> {props.t("mobile")}</Label>
                  <Input
                    type="tel"
                    name="phone"
                    id="exampleEmail"
                    placeholder=""
                    value={fields.phone}
                    onChange={onFieldsChange}

                  />
                </FormGroup>


                <FormGroup>
                  <Label for="exampleEmail"> {props.t("message")}</Label>
                  <Input
                    type="textarea"
                    name="message"
                    id="exampleEmail"
                    placeholder=""
                    value={fields.message}
                    required
                    onChange={onFieldsChange}
                  />
                </FormGroup>

                <Button className="btn_addform" type="submit">
                  {loading && <Spinner size="sm" color="light"/>}
                  {props.t("send")}</Button>
              </Form>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className="contact_form_img">
              <img src={Contactusimg} alt="" className="img-fluid"/>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default withTranslation()(Contactusform);
