import React from "react"

export default props => {
  return (
    <div className="label-content">
      <h4>{props.t("loyalty")}</h4>

      <p>
        <small></small>
      </p>
    </div>
  )
}
