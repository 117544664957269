import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Signup from "../pages/Authentication/Signup";

import ProfileEdit from "../pages/Authentication/EditProfile";
import ProfileChangePassword from "../pages/Authentication/ChangePassword";

import Dashboard from "../pages/Dashboard";

import Customers_List from "../pages/Customers/CustomersList";

import TransactionsList from "../pages/Transactions/List";
import TransactionView from "../pages/Transactions/View";
import CustomerSupportList from "../pages/CustomerSupport/List";
import CustomerSupportView from "../pages/CustomerSupport/View";
import ViewCustomer from "../pages/Customers/ViewCustomer";

import Bookingpage from "../pages/Bookings";
import Censor from "pages/Censor";
import Dashsubscription from "pages/Dashsubscription";
import Manageprofile from "pages/Manageprofile";
import Termsconditions from "pages/Termsconditions";
import Privacy from "pages/Privacy";
import Contactus from "pages/Contactus";
import Aboutus from "pages/Aboutus";
import Subscriptionsingle from "pages/Authentication/Subscriptionsingle";
import Cardholder from "pages/Authentication/Subscriptionsingle/Cardholder";
import Addnewcard from "pages/Authentication/Subscriptionsingle/Addnewcard";

import Settings from "../pages/Settings";

// Content Page
import ContentPage from "../pages/ContentPage/List";
import ContentPageAddEdit from "../pages/ContentPage/AddEdit";
import Faqs from "../pages/Faq/List";
import FaqAddEdit from "../pages/Faq/AddEdit";
import MyPaymentCards from "pages/MyPaymentCards";
import ParkingStructureList from "pages/Parking/List";
import ParkingAddEdit from "pages/Parking/AddEdit";
import CensorList from "pages/Censor/List";
import CensorAddEdit from "pages/Censor/AddEdit";
import BookingDetails from "pages/Bookings/View";
import PermitCardsList from "pages/PermitCards/List";
import SubscriptionPlans from "pages/UserSubscriptions/index";

import UserSubscription from "../pages/Customers/SubscriptionAssign/SubscriptionAssign";


const userRoutes = () => [
  // Logout
  { path: "/logout", exact: true, component: Logout },
  { path: "/edit-profile", exact: true, component: ProfileEdit },
  {
    path: "/change-password",
    exact: true,
    component: ProfileChangePassword,
  },
  { path: "/dashboard", component: Dashboard },
  { path: "/users", component: Customers_List },
  { path: "/users/profile/subscription-assign", component: UserSubscription },

  { path: "/bookings", component: Bookingpage },
  { path: "/sensors", component: CensorList },
  { path: "/sensors/add", component: CensorAddEdit },
  { path: "/sensors/:id/edit", component: CensorAddEdit },
  { path: "/subscription", component: Dashsubscription },
  { path: "/manage-profile", component: Manageprofile },
  { path: "/terms-conditions", component: Termsconditions },
  { path: "/privacy", component: Privacy },
  { path: "/contact-us", component: Contactus },
  { path: "/about-us", component: Aboutus },
  { path: "/users/profile/:id", component: ViewCustomer },
  { path: "/content-pages", component: ContentPage },
  { path: "/content-pages/add", component: ContentPageAddEdit },
  { path: "/content-pages/:id/edit", component: ContentPageAddEdit },
  { path: "/transactions", component: TransactionsList },
  { path: "/transaction/:id/view", component: TransactionView },
  { path: "/customer-support", component: CustomerSupportList },
  { path: "/customer-support/:id/view", component: CustomerSupportView },
  { path: "/general", component: Settings },
  { path: "/faqs", component: Faqs },
  { path: "/faqs/add", component: FaqAddEdit },
  { path: "/faqs/:id/edit", component: FaqAddEdit },
  { path: "/my-payment-cards", component: MyPaymentCards },
  { path: "/parking-structure", component: ParkingStructureList },
  { path: "/parking-structure/add", component: ParkingAddEdit },
  { path: "/parking-structure/:id/edit", component: ParkingAddEdit },
  
  { path: "/booking/:id/view", component: BookingDetails },
  { path: "/permitCards", component: PermitCardsList },
  {path : "/subscriptionPlans", component : SubscriptionPlans},
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/signup", component: Signup },
  { path: "/subscription-single", component: Subscriptionsingle },
  { path: "/cardholder", component: Cardholder },
  { path: "/addnewcard", component: Addnewcard },
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

export { userRoutes, authRoutes };
