import { call, put, takeLatest } from "redux-saga/effects"

import { GET_CITIES_REQUEST, GET_COMPANIES_REQUEST, GET_COUNTRIES_REQUEST, GET_GRAPH_DATA_REQUEST, GET_STATS_DATA_REQUEST, GET_TRANSACTION_REQUEST, GET_ZONES_REQUEST, IMPORT_SENSOR_REQUEST } from "./actionTypes"
import { getCitiesFail, getCitiesSuccess, getCompaniesFail, getCompaniesSuccess, getCountriesFail, getCountriesSuccess, getGraphDataFail, getGraphDataSuccess, getStatsDataFail, getStatsDataSuccess, getTransactionFail, getTransactionSuccess, getZonesFail, getZonesSuccess, importSensorFail, importSensorSuccess } from "./actions"

import { getCityListApi, getCompaniesListApi, getCountryListApi, getGraphData, getStatsData, getTransaction, getZoneListApi, importSensor } from "helpers/backend_helper"
import toastr from "toastr"
//company

function* getCompaniesReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getCompaniesListApi, data);

    if (response.status == "failure") {
      return yield put(getCompaniesFail(response.message));
    }

    yield put(getCompaniesSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getCompaniesFail(error));
  }
}


//country

function* getCountriesReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getCountryListApi, data);

    if (response.status == "failure") {
      return yield put(getCountriesFail(response.message));
    }

    yield put(getCountriesSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getCountriesFail(error));
  }
}


//city

function* getCitiesReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getCityListApi, data);

    if (response.status == "failure") {
      return yield put(getCitiesFail(response.message));
    }

    yield put(getCitiesSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getCitiesFail(error));
  }
}


//zones
function* getZonesReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getZoneListApi, data);

    if (response.status == "failure") {
      return yield put(getZonesFail(response.message));
    }

    yield put(getZonesSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getZonesFail(error));
  }
}



function* getGraphDataSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getGraphData, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(getGraphDataFail(response.message));
    }

    yield put(getGraphDataSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(getGraphDataFail(error));
  }
}


function* getStatsDataSaga({ payload: { data, callback } }) {
  try {
    console.log(data, "-----------------");
    const response = yield call(getStatsData, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(getStatsDataFail(response.message));
    }

    yield put(getStatsDataSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(getStatsDataFail(error));
  }
}


function* getTransactionSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getTransaction, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(getTransactionFail(response.message));
    }

    yield put(getTransactionSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(getStatsDataFail(error));
  }
}

function* importSensorCSVData({ payload: { data, callback } }) {
  try {
    const response = yield call(importSensor, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(importSensorFail(response.message));
    }

    yield put(importSensorSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(importSensorFail(error));
  }
}

function* DashboardSaga() {
  yield takeLatest(GET_COMPANIES_REQUEST, getCompaniesReq);
  yield takeLatest(GET_COUNTRIES_REQUEST, getCountriesReq);
  yield takeLatest(GET_CITIES_REQUEST, getCitiesReq);
  yield takeLatest(GET_ZONES_REQUEST, getZonesReq);
  yield takeLatest(GET_GRAPH_DATA_REQUEST, getGraphDataSaga);
  yield takeLatest(GET_STATS_DATA_REQUEST, getStatsDataSaga);
  yield takeLatest(GET_TRANSACTION_REQUEST, getTransactionSaga);
  yield takeLatest(IMPORT_SENSOR_REQUEST, importSensorCSVData);

}


export default DashboardSaga
