import {
  PAYMENT_API_FAIL,
  GET_PAYMENTS,
  GET_PAYMENTS_FAIL,
  GET_PAYMENTS_SUCCESS,
  ADD_PAYMENT,
  ADD_PAYMENT_FAIL,
  ADD_PAYMENT_SUCCESS,
  GET_PAYMENT,
  GET_PAYMENT_FAIL,
  GET_PAYMENT_SUCCESS,
  PUT_PAYMENT,
  PUT_PAYMENT_FAIL,
  PUT_PAYMENT_SUCCESS,
  DELETE_PAYMENT,
  DELETE_PAYMENT_FAIL,
  DELETE_PAYMENT_SUCCESS,
  PUT_PAYMENTS_STATUS,
  PUT_PAYMENTS_STATUS_FAIL,
  PUT_PAYMENTS_STATUS_SUCCESS,
} from "./actionTypes"

/* Get faqs */
export const getPayments = data => ({
  type: GET_PAYMENTS,
  payload: {data},
})

export const getPaymentsSuccess = faqs => ({
  type: GET_PAYMENTS_SUCCESS,
  payload: faqs,
})

export const getPaymentsFail = error => ({
  type: GET_PAYMENTS_FAIL,
  payload: error,
})
/* Get Payments END */

/* Add Payment */
export const addPayment = (data, callback) => ({
  type: ADD_PAYMENT,
  payload: { data, callback },
})

export const addPaymentFail = error => ({
  type: ADD_PAYMENT_FAIL,
  payload: error,
})

export const addPaymentSuccess = (faqs) => ({
  type: ADD_PAYMENT_SUCCESS,
  payload: faqs,
})
/* Add Payment END */

/* Get Payment */
export const getPayment = id => ({
  type: GET_PAYMENT,
  payload: id,
})

export const getPaymentFail = error => ({
  type: GET_PAYMENT_FAIL,
  payload: error,
})

export const getPaymentSuccess = faq => ({
  type: GET_PAYMENT_SUCCESS,
  payload: faq,
})
/* Get Payment END */

/* Update Payment */
export const putPayment = (data, history) => ({
  type: PUT_PAYMENT,
  payload: { data, history },
})

export const putPaymentFail = error => ({
  type: PUT_PAYMENT_FAIL,
  payload: error,
})

export const putPaymentSuccess = (faqs) => ({
  type: PUT_PAYMENT_SUCCESS,
  payload: faqs,
})
/* Update Payment END */

/* Delete Payment */
export const deletePayment = (data, callback) => ({
  type: DELETE_PAYMENT,
  payload: { data, callback },
})

export const deletePaymentFail = error => ({
  type: DELETE_PAYMENT_FAIL,
  payload: error,
})

export const deletePaymentSuccess = (faqs) => ({
  type: DELETE_PAYMENT_SUCCESS,
  payload: faqs,
})
/* Delete Faz END */

/* Update Multi Payments Status */
export const putPaymentsStatus = (data, callback) => ({
  type: PUT_PAYMENTS_STATUS,
  payload: { data, callback },
})

export const putPaymentsStatusFail = error => ({
  type: PUT_PAYMENTS_STATUS_FAIL,
  payload: error,
})

export const putPaymentsStatusSuccess = (faqs) => ({
  type: PUT_PAYMENTS_STATUS_SUCCESS,
  payload: faqs,
})
/* Update Multi Payments Status END */
