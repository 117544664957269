import { ADD_CITY_FAIL, ADD_CITY_REQUEST, ADD_CITY_SUCCESS, ADD_COMPANY_FAIL, ADD_COMPANY_REQUEST, ADD_COMPANY_SUCCESS, ADD_COUNTRY_FAIL, ADD_COUNTRY_REQUEST, ADD_COUNTRY_SUCCESS, ADD_ZONE_FAIL, ADD_ZONE_REQUEST, ADD_ZONE_SUCCESS, GET_CITIES_FAIL, GET_CITIES_REQUEST, GET_CITIES_SUCCESS, GET_CITY_FAIL, GET_CITY_REQUEST, GET_CITY_SUCCESS, GET_COMPANIES_FAIL, GET_COMPANIES_REQUEST, GET_COMPANIES_SUCCESS, GET_COMPANY_FAIL, GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, GET_COUNTRIES_FAIL, GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_COUNTRY_FAIL, GET_COUNTRY_REQUEST, GET_COUNTRY_SUCCESS, GET_ZONES_FAIL, GET_ZONES_REQUEST, GET_ZONES_SUCCESS, GET_ZONE_FAIL, GET_ZONE_REQUEST, GET_ZONE_SUCCESS, REMOVE_CITY_FAIL, REMOVE_CITY_REQUEST, REMOVE_CITY_SUCCESS, REMOVE_COMPANY_FAIL, REMOVE_COMPANY_REQUEST, REMOVE_COMPANY_SUCCESS, REMOVE_COUNTRY_FAIL, REMOVE_COUNTRY_REQUEST, REMOVE_COUNTRY_SUCCESS, REMOVE_ZONE_FAIL, REMOVE_ZONE_REQUEST, REMOVE_ZONE_SUCCESS, UPDATE_CITY_FAIL, UPDATE_CITY_REQUEST, UPDATE_CITY_SUCCESS, UPDATE_COMPANY_FAIL, UPDATE_COMPANY_REQUEST, UPDATE_COMPANY_SUCCESS, UPDATE_COUNTRY_FAIL, UPDATE_COUNTRY_REQUEST, UPDATE_COUNTRY_SUCCESS, UPDATE_ZONE_FAIL, UPDATE_ZONE_REQUEST, UPDATE_ZONE_SUCCESS } from "./actionTypes"
import * as CONST from "./actionTypes";



const INIT_STATE = {
  loading: false,
  error: "",
  companies: [],
  totalCompanies: 0,
  singleCompanyDetail: null,
  cities: [],
  totalCities: 0,
  singleCityDetail: null,
  countries: [],
  totalCountries: 0,
  singleCountryDetail: null,
  zones: [],
  totalZones: 0,
  singleZoneDetail: null,
  graphData: [],
  statsData: null,
  transactions: []
}

const dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    //company
    case GET_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action.payload.data,
      };

    case GET_COMPANIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    //country
    case GET_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload.data,
      };

    case GET_COUNTRIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    //city
    case GET_CITIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        cities: action.payload.data,
      };

    case GET_CITIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    //zone
    case GET_ZONES_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case GET_ZONES_SUCCESS:
      return {
        ...state,
        loading: false,
        zones: action.payload.data,
      };

    case GET_ZONES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case CONST.GET_GRAPH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case CONST.GET_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        graphData: action.payload.data || []
      };

    case CONST.GET_GRAPH_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        graphData: []
      };

    case CONST.GET_STATS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case CONST.GET_STATS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        statsData: action.payload.data || null
      };

    case CONST.GET_STATS_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        statsData: null
      };


    case CONST.GET_TRANSACTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case CONST.GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload.data || []
      };

    case CONST.GET_TRANSACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        transactions: []
      };
    case CONST.IMPORT_SENSOR_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case CONST.IMPORT_SENSOR_SUCCESS:
      return {
        ...state,
        loading: false,
        import: action.payload.data || [],
      };

    case CONST.IMPORT_SENSOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        import: [],
      };



    default:
      return state
  }
}

export default dashboard
