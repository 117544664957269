import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { Col, Input, Row, Spinner } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import ListColumns from "./Columns";
import { getBookingsRequest } from "store/actions";
import SearchInput from "components/Common/SearchInput";


const CustomerSupport = ({
  accessLevel,
  user,
  history,
  loading,
  bookingsList,
  getBookingsRequest,
  totalBookings,
  ...props
}) => {
  const [searchText, setSearchText] = useState("");

  const [filter, setFilter] = useState({
    orderBy: "_id",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fieldName: "",
    fieldValue: "",
  });

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalBookings,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    if (user?._id) {
      getBookingsRequest({ ...filter, ownerId: user?._id });
    }
  }, [user]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "created_date";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const onFilterChange = (value) => {
    setFilter((prevState) => ({
      ...prevState,
      fieldName: "type",
      fieldValue: value,
    }));
  };

  const toggleDetailsModal = (details) => {
    setdetailsModal((prevState) => ({
      isOpen: !prevState.isOpen,
      details,
    }));
  };


  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText }));
  };

  const onChangeStatusFilter = (data) => {
    setFilter(pre => ({ ...pre, status: data }))
  }

  useEffect(() => {
    getBookingsRequest({ ...filter, ownerId: user?._id  || "" });
  }, [filter]);

  return (
    <div className="mt-4">
      <Row>
        <Col xs="12">
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="_id"
                data={bookingsList || []}
                columns={ListColumns(
                  toggleDetailsModal,
                  history,
                  props.t
                )}
                bootstrap4
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row>
                      <Col lg={3} md={12} sm={12}>
                        <div className="custom-flex-sm d-flex align-items-center">

                          <div className="search d-flex align-items-center">
                            <div className="pl-3">
                              <SearchInput
                                {...toolkitProps.searchProps}
                                triggerSearch={onSearch}
                                placeholder={props.t("search")}
                                searchText={searchText}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={4} md={12} sm={12}>
                        <div className="custom-flex-sm d-flex align-items-center">
                          <div className="search d-flex align-items-center">
                            <div className="pl-3">
                              <Input
                                type="select"
                                name="status"
                                value={filter.status}
                                id="exampleEmail"
                                // placeholder="BG"
                                onChange={(e) => onChangeStatusFilter(e.target.value)}
                              >
                                <option value="">{props.t("status")}</option>
                                <option value="booked">Booked</option>
                                <option value="parked">Parked</option>
                                <option value="completed">Completed</option>

                              </Input>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive spinner-content">
                          <BootstrapTable
                            responsive
                            remote
                            bordered={false}
                            striped={false}
                            classes={
                              "table table-centered table-nowrap"
                            }
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            onTableChange={handleTableChange}
                            {...paginationTableProps}
                            defaultSorted={[
                              {
                                dataField: "createdAt",
                                order: "desc",
                              },
                            ]}
                            sort={{
                              sortCaret: (order) =>
                                order === "asc" ? (
                                  <span className="caret">
                                    <i className="bx bx-caret-up" />
                                  </span>
                                ) : order === "desc" ? (
                                  <span className="caret">
                                    <i className="bx bx-caret-down" />
                                  </span>
                                ) : null,
                            }}
                          />

                          {loading && (
                            <div className="spinner">
                              <Spinner color="primary" />
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-stakes-md-center mt-30">
                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ Bookings, Login }) => ({
  user: Login.user,
  loading: Bookings?.loading,
  bookingsList: Bookings?.bookingsList,
  totalBookings: Bookings?.totalBookings
});

const mapDispatchToProps = (dispatch) => ({
  getBookingsRequest: (data, callback) => dispatch(getBookingsRequest(data, callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerSupport))
);
