import React from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody } from "reactstrap"

const ImportModal = props => {
    const { isOpen, toggle, handleFileChange, handleUpload, uploadProgress, selectedFile } = props

    return (
        <Modal
            size="sm"
            isOpen={isOpen}
            autoFocus={true}
            centered={true}
            toggle={toggle}
        >
            <div className="modal-content remove-confirm">
                <ModalBody>

                    <div className="uploadFile my-2 p-3">
                        <h6>Import CSV FIle:</h6>
                        <input
                            type="file"
                            className="w-100 h-100"
                            onChange={handleFileChange}
                        />
                        {
                            selectedFile ?
                                <Button
                                    type="button"
                                    style={{ background: uploadProgress ? "" : '#24C18F', cursor: uploadProgress ? 'not-allowed' : "" }}
                                    className="btn-rounded waves-effect waves-light mt-3"
                                    onClick={uploadProgress ? "" : handleUpload}

                                >
                                    {
                                        uploadProgress ?
                                            'Uploading...'
                                            :
                                            'Submit'
                                    }
                                </Button>
                                :
                                <Button
                                    type="button"
                                    style={{ cursor: 'not-allowed' }}
                                    className="btn-rounded waves-effect waves-light mt-3"

                                >
                                    Submit
                                </Button>
                        }

                    </div>
                </ModalBody>
            </div>
        </Modal>
    )
}

ImportModal.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
}

export default ImportModal
