import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
/* import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import EditorUploadAdapterPlugin from "../../../plugins/ckeditor/UploadAdapterPlugin" */
import CKEditor from "react-ckeditor-component";
import FormButton from "components/Common/FormButtons";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";

import { addContentPage, getContentPage, putContentPage } from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";

const AddCustomer = ({
  accessLevel,
  history,
  error,
  onAddContentPage,
  onGetContentPage,
  onPutContentPage,
  contentPage,
  loading,
  ...props
}) => {
  const { id } = useParams();
  const didMountRef = useRef(null);

  const [accesses, setaccesses] = useState({
    canAdd: false,
    canEdit: false,
  });
  const [fields, setFields] = useState({
    title: "",
    content: "",
    status: "active",
    type: "",
  });

  useEffect(() => {
    if (!accessLevel) {
      const data = {
        canAdd: true,
        canEdit: true,
      };

      return setaccesses(data);
    }

    const data = {
      canAdd: false,
      canEdit: false,
    };

    accessLevel?.map((item) => {
      switch (item.label) {
        case "CREATE":
          data.canAdd = item.value;
          break;

        case "UPDATE":
          data.canEdit = item.value;
          break;
      }
    });

    setaccesses(data);
  }, [JSON.stringify(accessLevel)]);

  useEffect(() => {
    if (id) {
      onGetContentPage(id);
    }
  }, [id]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!!contentPage && !!contentPage.content) {
        try {
          setFields({
            title: contentPage.title || "",
            content: contentPage.content || "",
            status: contentPage.status || "active",
            type: contentPage.type || "",
          });
        } catch (err) {
          console.log("parsing error ", err);
        }
      }
    } else didMountRef.current = true;
  }, [JSON.stringify(contentPage)]);

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    if (id) {
      if (!accesses.canEdit) return;
      onPutContentPage({ _id: contentPage._id, ...fields }, history);
    } else {
      if (!accesses.canAdd) return;
      onAddContentPage({ ...fields }, history);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="ContentPages"
            breadcrumbItem={
              (id ? props.t("edit") : props.t("add")) +
              " " +
              props.t("Content Page")
            }
            breadcrumbItems={[
              { title: props.t("Content Pages"), link: "/content-pages" },
              { title: id ? props.t("edit") : props.t("add") },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Title")}
                          </Label>
                          <Input
                            type="text"
                            id="formrow-firstname-Input"
                            value={fields.title}
                            onChange={handleChange("title")}
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("Select Type")}</Label>

                          <Input
                            type="select"
                            value={fields.type}
                            onChange={handleChange("type")}
                            required
                          >
                            <option value=""></option>
                            <option value="ABOUT_US">
                              About Us
                            </option>
                            <option value="PRIVACY_POLICY">
                              Privacy Policy
                            </option>
                            <option value="TERMS_CONDITIONS">
                              Terms & Condition
                            </option>
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup>
                          <Label>{props.t("Content")}</Label>

                          <CKEditor
                            content={fields.content}
                            events={{
                              change: (evt) => {
                                const data = evt?.editor?.getData();

                                handleChange("content")({
                                  target: { value: data },
                                });
                              },
                            }}
                            config={{
                              height: 250,
                              toolbar: [
                                [
                                  "Undo",
                                  "Redo",
                                  "Font",
                                  "FontSize",
                                  "Styles",
                                  "Format",
                                  "-",
                                  "Maximize",
                                  "-",
                                  "Source",
                                ],
                                [
                                  "Bold",
                                  "Italic",
                                  "Underline",
                                  "Strike",
                                  "-",
                                  "RemoveFormat",
                                  "-",
                                  "NumberedList",
                                  "BulletedList",
                                ],
                                [
                                  "Link",
                                  "Unlink",
                                  "-",
                                  "JustifyLeft",
                                  "JustifyCenter",
                                  "JustifyRight",
                                  "JustifyBlock",
                                  "-",
                                  "Outdent",
                                  "Indent",
                                  "-",
                                  "TextColor",
                                  "BGColor",
                                ],
                                [
                                  "Image",
                                  "Table",
                                  "HorizontalRule",
                                  "SpecialChar",
                                  "-",
                                  "Blockquote",
                                ],
                              ],
                            }}
                            scriptUrl="https://cdn.ckeditor.com/4.16.0/full/ckeditor.js"
                          />

               
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>{props.t("status")}</Label>

                          <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              switch="none"
                              checked={fields.status == "active"}
                              onChange={() => {
                                const value =
                                  fields.status == "active"
                                    ? "inactive"
                                    : "active";

                                handleChange("status")({ target: { value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch1"
                              data-on-label={props.t("active")}
                              data-off-label={props.t("inactive")}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <FormButton
                      needSubmit={id ? accesses.canEdit : accesses.canAdd}
                      goBack={() => history.goBack()}
                    />

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddCustomer.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  contentPage: PropTypes.object,
  onAddContentPage: PropTypes.func,
  onGetContentPage: PropTypes.func,
  onPutContentPage: PropTypes.func,
};

const mapStateToProps = ({ content_page }) => ({
  error: content_page.error,
  loading: content_page.loading,
  contentPage: content_page.contentPage,
});

const mapDispatchToProps = (dispatch) => ({
  onAddContentPage: (data, history) => dispatch(addContentPage(data, history)),
  onGetContentPage: (id) => dispatch(getContentPage(id)),
  onPutContentPage: (data, history) => dispatch(putContentPage(data, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddCustomer))
);
