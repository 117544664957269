import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

// images
import Thumbman from "../../../src/assets/images/thumbchar.png";
import Booking from "../../../src/assets/images/bookingimg.png";
import Revnue from "../../../src/assets/images/revnue.png";
import Customer from "../../../src/assets/images/customer.png";
import { withTranslation } from "react-i18next";
import { getStatsDataRequest } from "store/actions";

const Welcomecards = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(({ Login }) => Login);

  const { statsData } = useSelector((s) => s.Dashboard);

  useEffect(() => {
    dispatch(getStatsDataRequest(`?isAdmin=false&parkingOwnerId=${user._id}`));
  }, []);

  return (
    <section className="Welcomecards_dashboard">
      <Container fluid>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <div className="welcome_card position-relative">
              <div className="charatcter_img">
                <img src={Thumbman} alt="" className="img-fluid" />
              </div>
              <h3>{props.t("welcome_back")} !</h3>
              <p>
                {props.t("to")} {props.t("fastparking")}
              </p>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <div className="common_card booking_card">
              <div className="inner_content">
                <p>{props.t("bookings")}</p>
                <h4>{statsData?.bookings || 0}</h4>
              </div>
              <div className="inner_img">
                <img src={Booking} alt="" className="img-fluid" />
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <div className="common_card revenue_card">
              <div className="inner_content">
                <p>{props.t("revenue")}</p>
                <h4>€ {parseInt(statsData?.revenue || 0)}</h4>
              </div>
              <div className="inner_img">
                <img src={Revnue} alt="" className="img-fluid" />
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <div className="common_card customer_card">
              <div className="inner_content">
                <p>{props.t("customers")}</p>
                <h4>{statsData?.owner_user || 0}</h4>
              </div>
              <div className="inner_img">
                <img src={Customer} alt="" className="img-fluid" />
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12} className="mt-5">
            <div className="common_card customer_card">
              <div className="inner_content">
                <p>{props.t("total_slots")}</p>
                <h4>{statsData?.total_slots || 0}</h4>
              </div>
              <div className="inner_img">
                {/* <img src={Booking} alt="" className="img-fluid" /> */}
              </div>
            </div>
          </Col>
          {/* <Col lg={3} md={6} sm={12} className="mt-5">
            <div className="common_card revenue_card">
              <div className="inner_content">
                <p>{props.t("errors")}</p>
                <h4>{statsData?.errors || 0}</h4>
              </div>
              <div className="inner_img">
              </div>
            </div>
          </Col> */}
          <Col lg={3} md={6} sm={12} className="mt-5">
            <div className="common_card welcome_card">
              <div className="inner_content">
                <p>{props.t("free_slots")}</p>
                <h4>{statsData?.free_slots || 0}</h4>
              </div>
              <div className="inner_img">
                {/* <img src={Customer} alt="" className="img-fluid" /> */}
              </div>
            </div>
          </Col><Col lg={3} md={6} sm={12} className="mt-5">
            <div className="common_card booking_card">
              <div className="inner_content">
                <p>{props.t("busy_slots")}</p>
                <h4>{statsData?.busy_slots || 0}</h4>
              </div>
              <div className="inner_img">
                {/* <img src={Customer} alt="" className="img-fluid" /> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default withTranslation()(Welcomecards);
