import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Spinner, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// action
import { userForgetPassword, userResetPassword, apiError } from "store/actions";

// import images

import logodark from "../../assets/images/logo.png";
import loginbaner from "../../assets/images/loginbaner.png";

const ForgotPassword = (props) => {
  const { loading, fields, handleChange, handleSubmit } = props;
  const history = useHistory();
  return (
    <div className="wraaping_login">
      <div className="form_Side">
        <div className="login_right_banner">
          <img src={loginbaner} alt="" className="img_fluid" />
        </div>

        <form
          id="loginform"
          onSubmit={handleSubmit}
          className="spinner-content forgot_password position-relative"
        >
          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          {props.forgetError && typeof props.forgetError === "string" ? (
            <Alert color="danger">{props.forgetError}</Alert>
          ) : null}

          {props.forgetSuccessMsg &&
          typeof props.forgetSuccessMsg === "string" ? (
            <Alert color="success">{props.forgetSuccessMsg}</Alert>
          ) : null}

          <div className="row">
            <div className="col-lg-12">
              <a href="javascript:void(0)" onClick={() => history.goBack()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                >
                  <path
                    d="M15 25L5 15L15 5L16.7812 6.75L9.78125 13.75H25V16.25H9.78125L16.7812 23.25L15 25Z"
                    fill="black"
                  />
                </svg>
              </a>
            </div>
            <div className="col-lg-12">
              <div className="input-group">
                <label>Email address</label>
                <input
                  type="email"
                  placeholder="Enter your Email Address"
                  value={fields.email}
                  onChange={handleChange("email")}
                />
              </div>
            </div>

            {/* <div className="col-lg-12">
              <div className="input-group">
                <label>Password</label>
                <input type="password" placeholder="*********" />
              </div>
            </div> */}

            {/* <div className="col-lg-12">
              <div className="input-group">
                <label>Confirm Password</label>
                <input type="password" placeholder="*********" />
              </div>
            </div> */}

            {/* <div className="col-lg-12">
              <div className="form-check forgot_check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" for="flexCheckDefault">
                   I agree all 
                </label>
                <Link to="">Term & Conditions</Link>
              </div>
            </div> */}

            <div className="col-lg-12">
              <button
                type="submit"
                className="themebtn fullwidthbtn"
                id="nextBtn"
              >
                Send
              </button>
            </div>

            
          </div>
        </form>
      </div>
    </div>
  );
};

const ResetPassword = (props) => {
  const { loading, fields, handleChange, handleSubmit } = props;
  const history = useHistory();

  return (
    <div className="wraaping_login">
      <div className="form_Side">
        <div className="login_right_banner">
          <img src={loginbaner} alt="" className="img_fluid" />
        </div>
        <form
          id="loginform"
          onSubmit={handleSubmit}
          className="spinner-content position-relative"
        >
          {loading && (
            <div className="spinner">
              <Spinner color="primary" />
            </div>
          )}

          {props.forgetError && typeof props.forgetError === "string" ? (
            <Alert color="danger">{props.forgetError}</Alert>
          ) : null}

          {props.forgetSuccessMsg &&
          typeof props.forgetSuccessMsg === "string" ? (
            <Alert color="success">{props.forgetSuccessMsg}</Alert>
          ) : null}

          <div className="row">
            <div className="col-lg-12">
              <h1>Reset Password</h1>
            </div>
            <div className="col-lg-12">
              <div className="input-group">
                <label>Enter OTP</label>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={fields.OTP}
                  onChange={handleChange("OTP")}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  value={fields.password}
                  onChange={handleChange("password")}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="input-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  placeholder="Enter Confirm Password"
                  value={fields.confirmPassword}
                  onChange={handleChange("confirmPassword")}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <button
                type="submit"
                className="themebtn fullwidthbtn"
                id="nextBtn"
              >
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const ForgetPasswordPage = (props) => {
  const { loading } = props;

  const [page, setpage] = useState("forgot");
  const [fields, setfields] = useState({
    email: "",
    OTP: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    props.apiError();
  }, []);

  const handleChange = (name) => (e) => {
    const { value } = e.target;

    setfields((prevState) => ({ ...prevState, [name]: value }));
  };

  function handleSubmit(e) {
    e.preventDefault();

    const callback = () => {
      setpage("reset");
    };

    props.userForgetPassword(fields, callback);
  }

  function handleResetSubmit(e) {
    e.preventDefault();

    const callback = () => {
      setTimeout(() => {
        history.push("/");
      }, 1000);
    };

    props.userResetPassword(fields, callback);
  }

  return (
    <section id="login">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-12">
            <div className="sign-in-top">
              <Link to="/">
                <img src={logodark} className="img-fluid registerlogo" />
              </Link>
            </div>
          </div> */}
        </div>

        {page === "forgot" ? (
          <ForgotPassword
            {...props}
            fields={fields}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
          />
        ) : page === "reset" ? (
          <ResetPassword
            {...props}
            fields={fields}
            handleSubmit={handleResetSubmit}
            handleChange={handleChange}
          />
        ) : null}
      </div>
    </section>
  );
};

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
  userResetPassword: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { forgetError, forgetSuccessMsg, loading } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg, loading };
};

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword, userResetPassword, apiError })(
    ForgetPasswordPage
  )
);
