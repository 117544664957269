import React from "react";
import { useHistory } from "react-router-dom";

// imaeges
import logodark from "../../../assets/images/logo.png";
import Cardholderimg from "../../../assets/images/cardholder.png";

const Cardholder = () => {
  const history = useHistory();

  return (
    <div id="login" className="wraaping_login">
      <div className="container">
        <div className="form_Side">
          <div className="login_right_banner">
            <img src={Cardholderimg} alt="" className="img_fluid" />
          </div>

          <form id="loginform" className="spinner-content position-relative">
            <a href="javascript:void(0)" onClick={() => history.goBack()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M15 25L5 15L15 5L16.7812 6.75L9.78125 13.75H25V16.25H9.78125L16.7812 23.25L15 25Z"
                  fill="black"
                />
              </svg>
            </a>
            <div className="sign-in-top">
              <img src={logodark} alt="" className="registerlogo" />
            </div>
            <div className="row">

              <div className="col-lg-12">
                <div className="input-group">
                  <label>Card Holder Name</label>
                  <input type="text" placeholder="Joan" />
                </div>
              </div>


              <div className="col-lg-12">
                <div className="input-group">
                  <label>Card Number</label>
                  <input type="text" placeholder="XXXX 5885 225 XXXX" />
                </div>
              </div>


              <div className="col-lg-6">
                <div className="input-group">
                  <label>Exp. Date</label>
                  <input type="date" placeholder="11/25" />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="input-group">
                  <label>CVV</label>
                  <input type="number" placeholder="***" />
                </div>
              </div>

              <div className="col-lg-12">
                <button type="button" className="themebtn fullwidthbtn">
                  Save
                </button>
              </div>


            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Cardholder;
