import {
    ADD_CONTACT_US_REQUEST,
    ADD_CONTACT_US_SUCCESS,
    ADD_CONTACT_US_FAIL,
  } from "./actionTypes"
  

  
  const initialState = {
    loading : false,
    error : ""
  }
  
  
  const Support = (state = initialState, action) => {
    switch (action.type) {
     
        case ADD_CONTACT_US_REQUEST:
          return {
          ...state,
          loading : true
          }
        case ADD_CONTACT_US_SUCCESS:
          return {
            ...state,
            loading : false,
          }    
      case ADD_CONTACT_US_FAIL:
        return {
          ...state,
          loading : false,
          error: action.payload,
        }
                     
    }
    return state
  }
  
  export default Support
  