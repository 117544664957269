import * as CONST from "./actionTypes";


//companies
export const getCompaniesRequest = (data, callback) => ({
  type: CONST.GET_COMPANIES_REQUEST,
  payload: { data, callback },
});

export const getCompaniesSuccess = (data) => ({
  type: CONST.GET_COMPANIES_SUCCESS,
  payload : data
});

export const getCompaniesFail = (error) => ({
  type: CONST.GET_COMPANIES_FAIL,
  payload: error,
});



//country
export const getCountriesRequest = (data, callback) => ({
  type: CONST.GET_COUNTRIES_REQUEST,
  payload: { data, callback },
});

export const getCountriesSuccess = (data) => ({
  type: CONST.GET_COUNTRIES_SUCCESS,
  payload : data
});

export const getCountriesFail = (error) => ({
  type: CONST.GET_COUNTRIES_FAIL,
  payload: error,
});

//city
export const getCitiesRequest = (data, callback) => ({
  type: CONST.GET_CITIES_REQUEST,
  payload: { data, callback },
});

export const getCitiesSuccess = (data) => ({
  type: CONST.GET_CITIES_SUCCESS,
  payload : data
});

export const getCitiesFail = (error) => ({
  type: CONST.GET_CITIES_FAIL,
  payload: error,
});


//zone
export const getZonesRequest = (data, callback) => ({
  type: CONST.GET_ZONES_REQUEST,
  payload: { data, callback },
});

export const getZonesSuccess = (data) => ({
  type: CONST.GET_ZONES_SUCCESS,
  payload : data
});

export const getZonesFail = (error) => ({
  type: CONST.GET_ZONES_FAIL,
  payload: error,
});


export const  getGraphDataRequest = (data, callback) => ({
  type: CONST.GET_GRAPH_DATA_REQUEST,
  payload: { data, callback },
});

export const getGraphDataSuccess = (data) => ({
  type: CONST.GET_GRAPH_DATA_SUCCESS,
  payload : data
});

export const getGraphDataFail = (error) => ({
  type: CONST.GET_GRAPH_DATA_FAIL,
  payload: error,
});


export const  getStatsDataRequest = (data, callback) => ({
  type: CONST.GET_STATS_DATA_REQUEST,
  payload: { data, callback },
});

export const getStatsDataSuccess = (data) => ({
  type: CONST.GET_STATS_DATA_SUCCESS,
  payload : data
});

export const getStatsDataFail = (error) => ({
  type: CONST.GET_STATS_DATA_FAIL,
  payload: error,
});


export const  getTransactionRequest = (data, callback) => ({
  type: CONST.GET_TRANSACTION_REQUEST,
  payload: { data, callback },
});

export const getTransactionSuccess = (data) => ({
  type: CONST.GET_TRANSACTION_SUCCESS,
  payload : data
});

export const getTransactionFail = (error) => ({
  type: CONST.GET_TRANSACTION_FAIL,
  payload: error,
});


export const  importSensorRequest = (data, callback) => ({
  type: CONST.IMPORT_SENSOR_REQUEST,
  payload: { data, callback },
});

export const importSensorSuccess = (data) => ({
  type: CONST.IMPORT_SENSOR_SUCCESS,
  payload : data
});

export const importSensorFail = (error) => ({
  type: CONST.IMPORT_SENSOR_FAIL,
  payload: error,
});
