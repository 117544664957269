import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
  Label,
  Button,
  Spinner,
  Form,
  Table,
} from "reactstrap";

//Import Breadcrumb

//Import actions
import { getCustomer, updateOwnerStatusRequest, getownerProfile} from "store/actions";
import moment from "moment";
import { withTranslation } from "react-i18next";
import UserBookings from "./UserBookings";
import { includes } from "lodash";

const ViewCustomer = (props) => {
  const [state, setstate] = useState({ status: "active" });
  const { data } = JSON.parse(localStorage.getItem("authUser"));
  const {
    match: { params },
    getCustomer,
    updateOwnerStatusRequest,
    customer,
    error,
    loading,
    updateStatusLoader,
    getownerProfile,
  } = props;
  
 

  const history = useHistory();
  
  const goToSubscription = () => {
    history.push({
      pathname: "/users/profile/subscription-assign",
      state: { customer, authOwner: data },
    });
  };
  const [ownerProfile, setOwnerProfile] = useState({})
  const [isLoading, setisLoading] = useState(false)

  let OnwerData = ownerProfile?.owner_clients
  
  useEffect(() => {
    if (params && params.id) {
      getCustomer(params.id);

      getownerProfile(
        {
          _id : data?._id
        },
        (response) => {
          if(response?.status === 'success'){
            setOwnerProfile(response?.data)
          }
  
        }
      );
    }
  }, [params]);
  const onChangeStatus =  () => {
    setisLoading(true)
    updateOwnerStatusRequest(
      {
        user_id: data?._id,
        client_id: params?.id,
        status : OnwerData?.includes(customer?._id) ? false : true
      },
      (response) => {
        setisLoading(false)
        if(response?.status === 'success'){
          getCustomer(response?.data?.clientId)
          
          getownerProfile(
            {
              _id : data._id
            },
            (response) => {
              if(response?.status === 'success'){
                setOwnerProfile(response?.data)
                console.log(response?.data)
               localStorage.setItem('ownerProfileFetched', JSON.stringify(response?.data)); 
              }
      
            }
          );
        }

      }
    );
  };
  console.log(ownerProfile, "ownerProfile")

  return (
    <React.Fragment>
      <div className="page-content">
        <section className="viewprofile_main">
          <Container>
            <a
              href="javascript:void(0)"
              className="backto_censor"
              onClick={() => history.push("/users")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.77 3.77L16 2L6 12L16 22L17.77 20.23L9.54 12L17.77 3.77Z"
                  fill="#2E3A59"
                ></path>
              </svg>
              {props.t("profile")}
            </a>

            <div className="profile_content">
              <Row>
                <Col lg={4} md={5} sm={12}>
                  <div className="common_profile_card profile_Card mb-3">
                    <div className="profile_picname">
                      <div className="profile_pic">
                        {/* <img src={User2} alt="" className="img-fluid" /> */}
                      </div>
                      <h3>{customer?.name || "NA"}</h3>
                    </div>
                    <div className="divider_line"></div>

                    <div className="profile_status">
                      <div className="status_profile">
                        <h4>
                          {customer?.createdAt &&
                            moment(customer?.createdAt).format("DD MMMM YYYY")}
                        </h4>
                      </div>
                      <div className="status_profile">
                        <h4 className="text-capitalize">{customer?.status}</h4>
                        <p>{props.t("status")}</p>
                      </div>
                    </div>
                    {/* <Button className="send_msg mt-3">Send Message</Button> */}
                  </div>

                  <div className="common_profile_card personal_Card mb-3">
                    <h5>{props.t("personal_information")}</h5>
                    <div className="divider_line"></div>
                    <ul className="personal_info_list">
                      <li>
                        <p>
                          <strong>{props.t("name")}</strong>
                        </p>
                        <p>{customer?.name || "NA"}</p>
                      </li>
                      <li>
                        <p>
                          <strong>{props.t("email")}</strong>
                        </p>
                        <p>{customer?.email || "NA"}</p>
                      </li>
                      <li>
                        <p>
                          <strong>{props.t("mobile")}</strong>
                        </p>
                        <p>
                          {customer?.countryCode + " " + customer?.mobileNumber}
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>{props.t("wallet")}</strong>
                        </p>
                        <p>${customer?.walletAmount || 0}</p>
                      </li>
                    </ul>
                  </div>

                  <div className="common_profile_card address_Card">
                    <div className="head_withbutton">
                      <h5>{props.t("address")}</h5>
                      {/* <Button className="edit_btn send_msg">Edit</Button> */}
                    </div>
                    <p>NA</p>
                  </div>
                  <br />
                  <div>
                    <Button
                      className={`common-btn ${OnwerData?.includes(customer?._id) ? 'btn btn-danger' : 'btn btn-primary' }`}
                      onClick={() => onChangeStatus()}
                    >
                      
                      {updateStatusLoader && (
                        <Spinner size="sm" color="white" />
                      )}
                      &nbsp; {OnwerData?.includes(customer?._id) ? 'Unblock' : 'Block' }
                    </Button>
                  </div>
                </Col>

                <Col lg={8} md={7} sm={12}>
                  <div className="common_profile_card">
                    <section className="latesttable_table">
                      <div className="latest_table_inner">
                        <h2 class="fillter_head pl-2 mb-2">
                          {props.t("parking")} {props.t("details")}
                        </h2>
                        <UserBookings userId={params?.id} />
                      </div>
                    </section>
                  </div>
                </Col>
                <Col>
              
                  {/* <Button
                      className="btn-danger"
                      onClick={() => goToSubscription()}
                      style={{ marginLeft: "4px", padding: "9px" ,marginTop: "10px"}}
                    >
                      Subscription
                    </Button> */}
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
  customer: customers.customer,
  updateStatusLoader: customers.updateStatusLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomer: (id) => dispatch(getCustomer(id)),
  updateOwnerStatusRequest: (data, callback) => dispatch(updateOwnerStatusRequest(data, callback)),
  getownerProfile: (data, callback) => dispatch(getownerProfile(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ViewCustomer));
