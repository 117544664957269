
import {  useState } from 'react';
import {Elements,CardElement,useElements,useStripe} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Spinner } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Modal, ModalHeader } from 'reactstrap';
import {addPayment} from "store/actions";
import { withTranslation } from 'react-i18next';



const AddPaymentCardModal = ({
    visible,
    rowData,
    onClose,
    onFinish,
    user,
    loading,
    onAddNewPayment,
    settings,
    t,
    ...args
   }) => {
    const {_id : userId} = user;

  const stripePromise = loadStripe(settings?.stripeKey?.[settings?.App_mode]?.public_key);

const toggle = () =>{
    onClose();
}

const onSuccessAddCard = (data) =>{
  onFinish && onFinish(data);
}

  return (
    <div>
      <Modal isOpen={visible} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>{t("add_payment_card")}</ModalHeader>
              <Elements stripe={stripePromise} >
                 <PaymentForm 
                    toggle={toggle} 
                    onSuccess={onSuccessAddCard}
                    submitting={loading}
                    onAddNewPayment={onAddNewPayment}
                    userId={userId}
                    t={t}
                    />
             </Elements>
      </Modal>
    </div>
  );
}


const mapStateToProps = ({ Payment, Login, Settings }) => ({
    user : Login.user || {},
    authToken : Login?.authToken || null,
    loading : Payment.loading,
    settings : Settings.settings, 
  });
  
  const mapDispatchToProps = (dispatch) => ({
    onAddNewPayment: (data,callback) => dispatch(addPayment(data,callback)) 
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddPaymentCardModal));



//PaymentForm.js
const PaymentForm = (props) => {
  const {toggle,onSuccess,onAddNewPayment,submitting,userId,t} = props;
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      if (!stripe || !elements || !userId) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
  
      // use stripe.createToken to get a unique token for the card
      const { error, token } = await stripe.createToken(cardElement);
  
      if (!error) {
        // Backend is not implemented yet, but once there isn’t any errors,
        // you can pass the token and payment data to the backend to complete
     const payload = {
        userId:userId,
        expDate:token.card.exp_month + "/" + token.card.exp_year.toString().slice(2,4),
        type:token.type,
        name:token.card.brand,
        token:token.id,
        lastd:token.card.last4,
        details:""
     }
     console.log("payload",payload)
       onAddNewPayment(payload,()=>onSuccess());
       
      } else {
        console.log(error);
      }
    }catch(error){
         console.log("Something went wrong",error)
    }
   
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='modal-body'>
         <CardElement />
      </div>
      <div className='modal-footer'>
          <Button color="secondary" onClick={toggle}>
            {t("cancel")}
          </Button>
          <Button type="submit">
          {submitting && <Spinner animation="border" variant="light" size='sm'/>} {t("add")}
          </Button>
      </div>

    </form>
  );
};

