import React from "react";
import { useHistory, Link } from "react-router-dom";

// imaeges
import logodark from "../../../assets/images/logo.png";
import Cardholderimg from "../../../assets/images/cardholder.png";
import Creditcard from "../../../assets/images/creditcard.png";
import Mastercard from "../../../assets/images/master.png";

const Addnewcard = () => {
  const history = useHistory();

  return (
    <div id="login" className="wraaping_login">
      <div className="container">
        <div className="form_Side">
          <div className="login_right_banner">
            <img src={Cardholderimg} alt="" className="img_fluid" />
          </div>

          <form id="loginform" className="spinner-content position-relative">
            <a href="javascript:void(0)" onClick={() => history.goBack()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M15 25L5 15L15 5L16.7812 6.75L9.78125 13.75H25V16.25H9.78125L16.7812 23.25L15 25Z"
                  fill="black"
                />
              </svg>
            </a>
            <div className="sign-in-top">
              <img src={logodark} alt="" className="registerlogo" />
            </div>
            <div className="row">
              <div class="plans">
                <label class="plan basic-plan" for="basic_001">
                  <input checked type="radio" name="plan" id="basic_001" />
                  <div class="plan-content">
                    <div class="user_selected">
                      <div class="card_img">
                        <img src={Creditcard} alt="" className="img-fluid" />
                      </div>
                      <div class="user_details">
                        <h5>Credit Card</h5>
                      </div>
                    </div>
                  </div>
                </label>

                <label class="plan complete-plan" for="basic_002">
                  <input type="radio" id="basic_002" name="plan" />
                  <div class="plan-content">
                    <div class="user_selected">
                      <div class="card_img">
                        <img src={Mastercard} alt="" className="img-fluid" />
                      </div>
                      <div class="user_details">
                        <h5>•••• •••• •••• •••• 4679</h5>
                      </div>
                    </div>
                  </div>
                </label>
              </div>

              <div className="col-lg-12">
                <button type="button" className="themebtn fullwidthbtn" onClick={()=> history.push("/cardholder")}>
                  Continue
                </button>
              </div>
              <div className="col-lg-12">
                <div className="addnew_card">
                  <Link to="/login">Add New Card</Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Addnewcard;
