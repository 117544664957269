import * as CONST from "./actionTypes";

export const apiFail = (error) => ({
  type: CONST.API_FAIL,
  payload: error,
});
export const loading = () => ({
  type: CONST.CUSTOMER_LOADING,
});

/* Get Customers */
export const getCustomers = (data,callback) => ({
  type: CONST.GET_CUSTOMERS,
  payload: {data,callback},
});

export const getCustomersSuccess = (customers) => ({
  type: CONST.GET_CUSTOMERS_SUCCESS,
  payload: customers,
});

export const getCustomersFail = (error) => ({
  type: CONST.GET_CUSTOMERS_FAIL,
  payload: error,
});
/* Get Customers END */

/* Get Customer */
export const getCustomer = (id) => ({
  type: CONST.GET_CUSTOMER,
  payload: id,
});

export const getCustomerFail = (error) => ({
  type: CONST.GET_CUSTOMER_FAIL,
  payload: error,
});

export const getCustomerSuccess = (customer) => ({
  type: CONST.GET_CUSTOMER_SUCCESS,
  payload: customer,
});
/* Get Customer END */

/** GET Plan Start */
export const getPlans = (data) => ({
  type: CONST.GET_ALL_PLANS,
  payload: data,
});

export const getPlansFail = (error) => ({
  type: CONST.GET_ALL_PLANS_FAIL,
  payload: error,
});

export const getPlansSuccess = (data) => ({
  type: CONST.GET_ALL_PLANS_SUCCESS,
  payload: data,
});
/** GET Plan End */

/** Update Store Status Start*/

export const updateStoreStatus = (data, callback) => ({
  type: CONST.UPDATE_STORE_STATUS,
  payload: { data, callback },
});

export const updateStoreStatusSuccess = (data) => ({
  type: CONST.UPDATE_STORE_STATUS_SUCCESS,
  payload: data?.data,
}
);

export const updateStoreStatusFail = (error) => ({
  type: CONST.UPDATE_STORE_STATUS_FAIL,
  payload: error,
});
/********** update owners status ******** */
export const updateOwnerStatusRequest = (data, callback) => ({
  type: CONST.UPDATE_OWNER_CLIENT_STATUS,
  payload: { data, callback },
});

export const updateOwnerStatusRequestSuccess = () => ({
  type: CONST.UPDATE_OWNER_CLIENT_STATUS_SUCCESS,
});

export const updateOwnerStatusRequestFail = (error) => ({
  type: CONST.UPDATE_OWNER_CLIENT_STATUS_FAIL,
  payload: error,
});
/**********  getownerProfile  ******** */

export const getownerProfile = (data, callback) => ({
  type: CONST.GET_OWNER_PROFILE_STATUS,
  payload: { data, callback },
});

export const getownerProfileSuccess = () => ({
  type: CONST.GET_OWNER_PROFILE_SUCCESS,
});

export const getownerProfileFail = (error) => ({
  type: CONST.GET_OWNER_PROFILE_FAIL,
  payload: error,
});

/** Update Store Status End*/

/** Update Store Plan Start*/
export const updateStorePlan = (data, callback) => ({
  type: CONST.UPDATE_STORE_PLAN,
  payload: { data, callback },
});

export const updateStorePlanSuccess = () => ({
  type: CONST.UPDATE_STORE_PLAN_SUCCESS,
});

export const updateStorePlanFail = (error) => ({
  type: CONST.UPDATE_STORE_PLAN_FAIL,
  payload: error,
});
/** Update Store Plan End*/

/** Get Store Transaction Start*/
export const storeTransactions = (data, callback) => ({
  type: CONST.GET_STORE_TRANSACTIONS,
  payload: { data, callback },
});

export const storeTransactionsSuccess = (data) => ({
  type: CONST.GET_STORE_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const storeTransactionsFail = (error) => ({
  type: CONST.GET_STORE_TRANSACTIONS_FAIL,
  payload: error,
});

/** Get Store Transaction End*/

/** Store Charge Start */
export const storeCharge = (data, callback) => ({
  type: CONST.POST_STORE_CHARGE,
  payload: { data, callback },
});

export const storeChargeSuccess = () => ({
  type: CONST.POST_STORE_CHARGE_SUCCESS,
});

export const storeChargeFail = (error) => ({
  type: CONST.POST_STORE_CHARGE_FAIL,
  payload: error,
});

/** Store Charges End */

/** Refund Store Charges Start */
export const refundCharge = (data, callback) => ({
  type: CONST.POST_STORE_REFUND,
  payload: { data, callback },
});

export const refundChargeSuccess = () => ({
  type: CONST.POST_STORE_REFUND_SUCCESS,
});

export const refundChargeFail = (error) => ({
  type: CONST.POST_STORE_REFUND_FAIL,
  payload: error,
});
/** Refund Store Charges End */

/**Store User Login Start */
export const storeUserLogin = (data, callback) => ({
  type: CONST.STORE_USER_LOGIN,
  payload: { data, callback },
});

export const storeUserLoginSuccess = () => ({
  type: CONST.STORE_USER_LOGIN_SUCCESS,
});
/** Store User Login End */

/* Give Discount on store plan */
export const putStorePlanDiscount = (data) => ({
  type: CONST.PUT_STORE_PLAN_DISCOUNT,
  payload: data,
});

export const putStorePlanDiscountSuccess = () => ({
  type: CONST.PUT_STORE_PLAN_DISCOUNT_SUCCESS,
});

export const putStorePlanDiscountFail = (error) => ({
  type: CONST.PUT_STORE_PLAN_DISCOUNT_FAIL,
  payload: error,
});
/* Give Discouhnt on store plan END */
/* Delete Store */
export const deleteStore = (data, callback) => ({
  type: CONST.DELETE_STORE,
  payload: { data, callback },
});

export const deleteStoreSuccess = () => ({
  type: CONST.DELETE_STORE_SUCCESS,
});

export const deleteStoreFail = (error) => ({
  type: CONST.DELETE_STORE_FAIL,
  payload: error,
});
/* Delete Store END */

/* PUT STORE KEYS */
export const putStoreKeys = (data) => ({
  type: CONST.PUT_STORE_KEYS,
  payload: data,
});
export const putStoreKeysSuccess = (data) => ({
  type: CONST.PUT_STORE_KEYS_SUCCESS,
  payload: data,
});
export const putStoreKeysFail = (error) => ({
  type: CONST.PUT_STORE_KEYS_FAIL,
  payload: error,
});
/* PUT STORE KEYS END */

/* Store Check mail */
export const postCheckMail = (data, callback) => ({
  type: CONST.POST_CHECK_MAIL,
  payload: { data, callback },
});

export const postCheckMailSuccess = () => ({
  type: CONST.POST_CHECK_MAIL_SUCCESS,
});

export const postCheckMailFail = (error) => ({
  type: CONST.POST_CHECK_MAIL_FAIL,
  payload: error,
});

export const postHideThings = (data, callback) => ({
  type: CONST.POST_HIDE_THINGS,
  payload: { data, callback },
});

export const postHideThingsSuccess = () => ({
  type: CONST.POST_HIDE_THINGS_SUCCESS,
});

export const postHideThingsFail = (error) => ({
  type: CONST.POST_HIDE_THINGS_FAIL,
  payload: error,
});
/* Store check mail END */

/* Change status */
export const changeStatus = (data) => ({
  type: CONST.CHANGE_STATUS,
  payload: data,
});
export const changeStatusSuccess = (data) => ({
  type: CONST.CHANGE_STATUS_SUCCESS,
  payload: data,
});
export const changeStatusFail = (error) => ({
  type: CONST.CHANGE_STATUS_FAIL,
  payload: error,
});
/* Change status */
