import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import {
    Bar,
    BarChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    CartesianGrid,
    Legend,
    YAxis,
  } from "recharts";

export const GraphWidget = (props) => {
    const { title, data, dataKey, color, loading } = props;
    return (
        <Card className={`jr-card jr-card-widget chart-card-bg`}>
            <p className="p-3">{title ? <CardTitle><h5 className="common-heading">{title}</h5></CardTitle> : null}</p>
            {loading ? (
          <div className="text-center">
            Loading...
          </div>
        ) : (
          <CardBody>
            <ResponsiveContainer width="100%" height={265}>
              <BarChart
                data={data}
                margin={{ top: 10, right: 0, left: -25, bottom: 0 }}
              >
                <XAxis dataKey="name" />
                <YAxis prefix="$" />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                <Bar dataKey={dataKey} fill={color} />
              </BarChart>
            </ResponsiveContainer>
          </CardBody>
        )}
        </Card>
    );
  };