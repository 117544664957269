import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Form } from "reactstrap";

import Welcomegreet from "components/Welcomegreet";
import Welcomecards from "components/Welcomecards";

//i18n
import { withTranslation } from "react-i18next";

import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

import SearchInput from "components/Common/SearchInput";

import { getCustomers, getownerProfile } from "store/actions";
import UsersColumns from "./Columns";
import UsersConfirmModal from "./ConfirmModal";

const Users = (props) => {
  const {
    history,
    users,
    user: authUser,
    totalUsers,
    onGetUsers,
    loading,
    getownerProfile,
  } = props;
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });
  const [ownerProfile, setOwnerProfile] = useState({})

  const [filter, setFilter] = useState({
    orderBy: "createdAt",
    order: -1,
    page: 1,
    limit: 10,
    ownerId: authUser?._id,
  });
  const [searchText, setSearchText] = useState("");
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalUsers,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };
  const getOwnerProfile_ = () => {
    getownerProfile(
      {
        _id : authUser?._id
      },
      (response) => {
        if(response?.status === 'success'){
          setOwnerProfile(response?.data)
          localStorage.setItem('ownerProfileFetched', JSON.stringify(response?.data)); 
        }
  
      }
    );
  } 
  
  useEffect(() => {
    getOwnerProfile_()
    },[])

 useEffect(() => {
  setTimeout(() => {
  if (ownerProfile && authUser?._id) { // Check if ownerProfile is available
    onGetUsers({ ...filter, ownerId: authUser?._id });
  }
},2000)
}, [ownerProfile, authUser, JSON.stringify(filter)]); // Fetch users when ownerProfile and authUser are available or when filter changes
  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (userId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  const removeConfirm = () => {
    const { userId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetUsers({ ...filter, ownerId: authUser?._id });
    };

    setConfirmModal({ isOpen: !isOpen, userId: null });
    onDeleteUser({ _id: userId }, onComplete);
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText }));
  };


  // Function to convert data to CSV and trigger download
  const convertAndDownloadCSV = () => {
    // Convert the data to a CSV string
    const selectedFields = ['_id', 'name', 'full_phone_number', 'status'];
    const csv = convertDataToCSV(users, selectedFields);

    // Create a Blob object for the CSV data
    const blob = new Blob([csv], { type: 'text/csv' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${new Date().getTime()}.csv`;

    // Trigger the download
    a.click();

    // Clean up resources
    window.URL.revokeObjectURL(url);
  };

  // Function to convert data to CSV format
  const convertDataToCSV = (data) => {
    // Implement the logic to convert your data into CSV format here
    // For a more complex data structure, you may want to use a library like 'json2csv'
    const header = Object.keys(data[0]).join(',') + '\n';
    const csvData = data.map((row) => Object.values(row).join(',')).join('\n');
    return header + csvData;
  };

  return (
    <React.Fragment>
      <UsersConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <div className="page-content">
        <Container fluid>
          <Welcomegreet />
          <Welcomecards />
          <div className="fillter_ssection mt-5">
            <Form>
              <div className="latest_cust_table mt-4">
                {/* <Newlist/> */}

                <Row>
                  <Col xs="12">
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          data={users}
                          columns={UsersColumns(
                            ownerProfile,
                            props.t, 
                            "text", 
                            )}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col lg={6} md={12} sm={12}>
                                  <div className="custom-flex-sm d-flex align-items-center">
                                    <div className="search d-flex align-items-center">
                                      <div className="pl-3">
                                        <SearchInput
                                          {...toolkitProps.searchProps}
                                          triggerSearch={onSearch}
                                          placeholder={props.t("search")}
                                          searchText={searchText}
                                        />
                                      </div>
                                      <div className="pl-3">
                                        <span onClick={convertAndDownloadCSV} style={{ fontSize: '17px', cursor: "pointer" }}>
                                          <i className="fas fa-file-export"></i>Export Data
                                        </span>
                                      </div>

                                      {/* {!!filter.fieldName ||
                            (!!filter.search && (
                              <div
                                className="mr-4"
                                style={{
                                  minWidth: "73px",
                                }}
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setFilter((prevState) => ({
                                      ...prevState,
                                      fields: {
                                        fieldName: "",
                                        fieldValue: "",
                                      },
                                      search: "",
                                    }))
                                    setSearchText("")
                                  }}
                                  className=""
                                >
                                  {props.t("clear_filters")}
                                </Link>
                              </div>
                            ))} */}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive spinner-content mt-3">
                                    <BootstrapTable
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                      defaultSorted={[
                                        {
                                          dataField: "createdAt",
                                          order: "desc",
                                        },
                                      ]}
                                      sort={{
                                        sortCaret: (order) =>
                                          order === "asc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-up" />
                                            </span>
                                          ) : order === "desc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-down" />
                                            </span>
                                          ) : null,
                                      }}
                                    />

                                    {loading && (
                                      <div className="spinner">
                                        <Spinner color="primary" />
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ customers, Login }) => ({
  loading: customers.loading,
  users: customers.customers,
  totalUsers: customers.totalCustomers,
  user: Login.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUsers: (data) => {
      dispatch(getCustomers(data));
    },
    getownerProfile: (data, callback) => dispatch(getownerProfile(data, callback)),

  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users))
);
