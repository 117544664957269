import { call, put, takeEvery } from "redux-saga/effects"
import toastr from "toastr"
// Payment Redux States
import {
  GET_PAYMENTS,
  ADD_PAYMENT,
  GET_PAYMENT,
  PUT_PAYMENT,
  DELETE_PAYMENT,
  PUT_PAYMENTS_STATUS,
} from "./actionTypes"
import {
  getPaymentsFail,
  getPaymentsSuccess,
  addPaymentFail,
  addPaymentSuccess,
  getPaymentFail,
  getPaymentSuccess,
  putPaymentFail,
  putPaymentSuccess,
  deletePaymentFail,
  deletePaymentSuccess,
  putPaymentsStatusFail,
  putPaymentsStatusSuccess,
} from "./actions"

import {
  getPayments,
  postPayment,
  getPayment,
  putPayment,
  deletePayment,
  putPaymentsStatus,
} from "../../helpers/backend_helper"

function* fetchPayments({payload : {data}}) {
  console.log("data",data)
  try {
    const response = yield call(getPayments, data)

    if (response.status == "failure") {
      return yield put(getPaymentsFail(response.message))
    }

    yield put(getPaymentsSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getPaymentsFail(error))
  }
}

function* onAddNewPayment({ payload: { data, callback } }) {
  try {
    const response = yield call(postPayment, data)
    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(addPaymentFail(response.message))
    }
    yield put(addPaymentSuccess(response))
    callback && callback()
    toastr.success("Payment card add successfully!");
  } catch (error) {
    yield put(addPaymentFail("Internal Error!"))
    toastr.error("Internal Error!");
  }
}

function* fetchPayment({ payload }) {
  try {
    const response = yield call(getPayment, payload)

    if (response.status == "failure") {
      return yield put(getPaymentFail(response.message))
    }

    yield put(getPaymentSuccess(response))
  } catch (error) {
    yield put(getPaymentFail(error))
  }
}

function* onPutPayment({ payload: { data, history } }) {
  try {
    const response = yield call(putPayment, data)

    if (response.status == "failure") {
      return yield put(putPaymentFail(response.message))
    }

    history && history.replace("/promos")

    yield put(putPaymentSuccess(response))
  } catch (error) {
    yield put(putPaymentFail("Internal Error!"))
  }
}

function* onDeletePayment({ payload: { data, callback } }) {
  try {
    const response = yield call(deletePayment, data)
    if (response.status == "failure") {
      return yield put(deletePaymentFail(response.message))
    }

    yield put(deletePaymentSuccess(data))
    callback && callback()
  } catch (error) {
    yield put(deletePaymentFail("Internal Error!"))
  }
}

function* onPutPaymentsStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putPaymentsStatus, data)

    if (response.status == "failure") {
      return yield put(putPaymentsStatusFail(response.message))
    }

    yield put(putPaymentsStatusSuccess(response))
    callback && callback()
  } catch (error) {
    yield put(putPaymentsStatusFail("Internal Error!"))
  }
}

function* PaymentsSaga() {
  yield takeEvery(GET_PAYMENTS, fetchPayments)
  yield takeEvery(ADD_PAYMENT, onAddNewPayment)
  yield takeEvery(GET_PAYMENT, fetchPayment)
  yield takeEvery(PUT_PAYMENT, onPutPayment)
  yield takeEvery(DELETE_PAYMENT, onDeletePayment)
  yield takeEvery(PUT_PAYMENTS_STATUS, onPutPaymentsStatus)
}

export default PaymentsSaga
