import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import worldCountries from "world-countries";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import queryString from "query-string";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Spinner,
} from "reactstrap";
import Select from "react-select";

import { currentUser, editProfile } from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";

//i18n
import { withTranslation } from "react-i18next";

// Images
import avatar4 from "assets/images/users/avatar-9.jpg";

const optionGroup = worldCountries
  .filter(
    (country) => !!country.flag && country.name.common && country.idd.root
  )
  .map((country) => ({
    name: country.name.common,
    label: country.idd.root + country.idd.suffixes[0] || "",
    value: country.name.common,
    flag: country.flag,
  }));

const CustomOptionComponent = ({ innerProps, innerRef, data, ...props }) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`country-code-option p-2${
        props.isSelected ? " selected" : ""
      }`}
    >
      <span className="flag">{data.flag}</span>
      <span className="country-code">{data.label}</span>
      <div className="country-name">{data.name}</div>
    </div>
  );
};

/**
 * Formats the size
 */
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const AddDriver = ({
  history,
  error,
  onGetCurrentUser,
  onPutProfile,
  admin,
  loading,
  ...props
}) => {
  const [fields, setFields] = useState({
    name: "",
    mobileNumber: "",
    countryCode: "",
    profileImage: null,
    address: "",
    email: "",
  });
  const [countryCodeData, setCountryCode] = useState({});
  const [selectedFiles, setselectedFiles] = useState([]);

  useEffect(() => {
    onGetCurrentUser();
  }, []);

  useEffect(() => {
    setFields({
      _id: admin._id || "",
      name: admin.name || "",
      mobileNumber: admin.mobileNumber || "",
      countryCode: admin.countryCode || "",
      profileImage: admin.profileImage || null,
      address: admin.address || "",
      email: admin.email || "",
    });

    const optionGroupIndex = optionGroup.findIndex(
      (data) => data.label === admin.countryCode || ""
    );

    if (optionGroupIndex !== -1) {
      setCountryCode(optionGroup[optionGroupIndex]);
    }

    if (admin.profileImage && admin.profileImage.link) {
      setselectedFiles([{ preview: admin.profileImage.link }]);
    }
  }, [JSON.stringify(admin), props.bankFields]);

  const handleChange = (name) => (event) => {
    setFields((prevState) => ({ ...prevState, [name]: event.target.value }));
  };

  /* Hit Update Customer API on Image Upload Success */
  const uploadFileSuccess = (response) => {
    // onPutCustomer({ _id: customer._id, profileImage: response.data._id })
    console.log(response);
    setFields((prevState) => ({
      ...prevState,
      profileImage: response.data._id,
    }));
  };

  /* Function to Accept Files in Dropzone */
  function handleAcceptedFiles(files) {
    if (id && !accesses.canEdit) return;
    onUploadFile({ image: files[0] }, uploadFileSuccess);

    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setselectedFiles(files);
  }

  const handleCountryCode = (data) => {
    setFields((prevState) => ({ ...prevState, countryCode: data.label }));
    setCountryCode(data);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (loading) return;

    onPutProfile({ _id: admin._id, ...fields }, history);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbItem={props.t("Edit") + " " + props.t("Profile")}
            breadcrumbItems={[
              {
                title: props.t("Edit Profile"),
              },
            ]}
          />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Form onSubmit={onSubmit} className="spinner-content">
                    {error && typeof error === "string" ? (
                      <Alert color="danger">{error}</Alert>
                    ) : null}

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-firstname-Input">
                            {props.t("Name")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="formrow-firstname-Input"
                            value={fields.name || ""}
                            onChange={handleChange("name")}
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-mobilenumber-Input">
                            {props.t("Mobile Number")}
                          </Label>

                          <Row>
                            <Col md={2}>
                              <Select
                                placeholder=""
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                                components={{ Option: CustomOptionComponent }}
                                inputId={"country-code-select"}
                                styles={{
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "4px",
                                  }),
                                  menu: (provided) => {
                                    return {
                                      ...provided,
                                      minWidth: "200px",
                                    };
                                  },
                                }}
                                onChange={handleCountryCode}
                                value={countryCodeData}
                              />
                            </Col>

                            <Col md={10}>
                              <Input
                                type="text"
                                className="form-control"
                                id="formrow-mobilenumber-Input"
                                value={fields.mobileNumber || ""}
                                onChange={handleChange("mobileNumber")}
                                required
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="formrow-email-Input">
                            {props.t("Email")}
                          </Label>
                          <Input
                            type="email"
                            className="form-control"
                            id="formrow-email-Input"
                            value={fields.email || ""}
                            onChange={handleChange("email")}
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="formrow-profile-image-Input">
                            {props.t("Profile Image")}
                          </Label>

                          <Dropzone
                            onDrop={(acceptedFiles) => {
                              handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => {
                              const imageFile = selectedFiles[0];

                              return (
                                <div
                                  className="dropzone-single-image avatar-xl"
                                  {...getRootProps()}
                                >
                                  <input
                                    {...getInputProps()}
                                    id="formrow-profile-image-Input"
                                    multiple={false}
                                  />

                                  <img
                                    className="rounded-circle avatar-xl"
                                    alt={
                                      !!imageFile && imageFile.name
                                        ? imageFile.name
                                        : "MintFactory"
                                    }
                                    src={
                                      !!imageFile ? imageFile.preview : avatar4
                                    }
                                  />

                                  <div className="edit">
                                    <i className="bx bx-pencil"></i>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="mt-4">
                      <button type="submit" className="btn btn-primary w-md">
                        {props.t("Submit")}
                      </button>

                      <button
                        type="button"
                        className="btn outline btn-outline-secondary w-md ml-3"
                        onClick={() => history.goBack()}
                      >
                        <i className="mdi mdi-arrow-left mr-1"></i>
                        {props.t("Back")}
                      </button>
                    </div>

                    {loading && (
                      <div className="spinner">
                        <Spinner color="primary" />
                      </div>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddDriver.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  admin: PropTypes.object,
  onGetCurrentUser: PropTypes.func,
  onPutProfile: PropTypes.func,
};

const mapStateToProps = ({ Login, Profile }) => ({
  error: Profile.error,
  loading: Profile.loading,
  admin: Login.user,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCurrentUser: () => dispatch(currentUser()),
  onPutProfile: (data, history) => dispatch(editProfile(data, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AddDriver))
);
