import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Alert
import Alert from "./Alert/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import Register from "./auth/register/reducer";
import Settings from "./Settings/reducer";
import customers from "./customers/reducer";
import Stake from "./stake/reducer";

// Files
import File from "./File/reducer";

// Faqs
import documents from "./document/reducer";

// Content Page
import content_page from "./contentpage/reducer";
import collections from "./collection/reducer";
import Activities from "./activity/reducer";
// Faqs
import faqs from "./faq/reducer";

// nft items
import items from "./items/reducer";
import Categoryes from "./categoryes/reducer"
import Payment from "./payment/reducer"
//Subscriptions
import Subscriptions from "./subscriptions/reducer";

//Parkings
import Parkings from "./parkings/reducer";
//sensor
import Sensors from "./sensor/reducer";
import Dashboard from "./dashboard/reducer";

import Support from "./support/reducer";

import Bookings from "./bookings/reducer";

const rootReducer = combineReducers({
  // public
  collections,
  Layout,
  Activities,
  Alert,
  Login,
  Stake,
  content_page,
  ForgetPassword,
  Profile,
  Register,
  Settings,
  customers,
  File,
  documents,
  faqs,
  items,
  Categoryes,
  Payment,
  Subscriptions,
  Parkings,
  Sensors,
  Dashboard,
  Support,
  Bookings
});

export default rootReducer;
