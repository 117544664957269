import * as CONST from "./actionTypes";

const INIT_STATE = {
    loading : false,
    error : "",
    parkingStructures : [],
    totalParkingStructures : 0,
    singleParkingStructure : null,
   addUpdateDeleteLoading: false,
   permitCards: [],
   totalPermitCards: 0,
   assignRemovePermitLoading: false,
    userVehicleList : []
};

const Parkings = (state = INIT_STATE, action) => {
  switch (action.type) {
     case CONST.GET_PARKING_STRUCTURES_REQUEST:
     return {
        ...state,
        loading : true,
        error : ""
     }
     case CONST.GET_PARKING_STRUCTURES_SUCCESS:
     return {
        ...state,
        loading : false,
        parkingStructures : action.payload.data,
        totalParkingStructures : action.payload.totalCount,
        error : ""
     }
     case CONST.GET_PARKING_STRUCTURES_FAIL:
        return {
           ...state,
           loading : false,
           error : action.payload
        }

     case CONST.GET_PARKING_STRUCTURE_REQUEST:
     return {
        ...state,
        loading : false,
        error : ""
     }
     case CONST.GET_PARKING_STRUCTURE_SUCCESS:
      console.log("get parking structure reducer",action.payload)
     return {
        ...state,
        loading : true,
        singleParkingStructure : action.payload.data?.resdata,
        error : ""
     }
     case CONST.GET_PARKING_STRUCTURE_FAIL:
     return {
        ...state,
        loading : false,
        error : action.payload
     }

     case CONST.ADD_PARKING_STRUCTURE_REQUEST:
        return {
           ...state,
           addUpdateDeleteLoading : true,
           error : ""
        }
        case CONST.ADD_PARKING_STRUCTURE_SUCCESS:
        return {
           ...state,
           addUpdateDeleteLoading : false,
           error : ""
        }
        case CONST.ADD_PARKING_STRUCTURE_FAIL:
        return {
           ...state,
           addUpdateDeleteLoading : false,
           error : action.payload
        }

        case CONST.UPDATE_PARKING_STRUCTURE_REQUEST:
            return {
                ...state,
                addUpdateDeleteLoading : true,
                error : ""
            }
            case CONST.UPDATE_PARKING_STRUCTURE_SUCCESS:
            return {
                ...state,
                addUpdateDeleteLoading : false,
                error : ""
            }
            case CONST.UPDATE_PARKING_STRUCTURE_FAIL:
            return {
                ...state,
                addUpdateDeleteLoading : false,
                error : action.payload
            }   
        
        case CONST.DELETE_PARKING_STRUCTURE_REQUEST:
            return {
                ...state,
                addUpdateDeleteLoading : true,
                error : ""
            }
            case CONST.DELETE_PARKING_STRUCTURE_SUCCESS:
            return {
                ...state,
                addUpdateDeleteLoading : false,
                error : ""
            }
            case CONST.DELETE_PARKING_STRUCTURE_FAIL:
            return {
                ...state,
                addUpdateDeleteLoading : false,
                error : action.payload
        }   
      //permit cards
      case CONST.LIST_PERMIT_CARDS_REQUEST:
         return {
            ...state,
            loading : false,
   
            error : ""
         }
         case CONST.LIST_PERMIT_CARDS_SUCCESS:
            return {
               ...state,
               loading : false,
               error: "",
               permitCards : action.payload.data,
               totalPermitCards : action.payload.totalCount,
            }
    
         case CONST.LIST_PERMIT_CARDS_FAIL:
         return {
            ...state,
            loading : false,
            error : action.payload
         }
        
            case CONST.ASSIGN_PERMIT_CARD_REQUEST:
             return {
               ...state,
               assignRemovePermitLoading : true,
               error : ""
            }
            case CONST.ASSIGN_PERMIT_CARD_SUCCESS:
               return {
                  ...state,
                  assignRemovePermitLoading : false,
                  error : ""
               }
       
            case CONST.ASSIGN_PERMIT_CARD_FAIL:
            return {
               ...state,
               assignRemovePermitLoading : false,
               error : action.payload
            }
            case CONST.REMOVE_PERMIT_CARD_REQUEST:
               return {
                 ...state,
                 assignRemovePermitLoading : true,
                 error : ""
              }
              case CONST.REMOVE_PERMIT_CARD_SUCCESS:
                 return {
                    ...state,
                    assignRemovePermitLoading : false,
                    error : ""
                 }
         
              case CONST.REMOVE_PERMIT_CARD_FAIL:
              return {
                 ...state,
                 assignRemovePermitLoading : false,
                 error : action.payload
              }
              case CONST.GET_USER_VEHICALS_REQUEST:
                  return {
                  ...state,
                  loading : true,
                     error: "",
                  userVehicleList : []
               }
              case CONST.GET_USER_VEHICALS_SUCCESS:
                 return {
                    ...state,
                    loading : false,
                    error: "",
                    userVehicleList : action.payload.data
                 }
         
              case CONST.GET_USER_VEHICALS_FAIL:
              return {
                 ...state,
                 loading : false,
                 error: action.payload,
                 userVehicleList : []
              }


              case CONST.GET_CARPARK_CODE_REQUEST:
               return {
                 ...state,
                 loading: true,
                 error: "",
                 carParkCode: [],
               };
             case CONST.GET_CARPARK_CODE_SUCCESS:
               return {
                 ...state,
                 loading: false,
                 error: "",
                 carParkCode: action.payload.data,
               };
         
             case CONST.GET_CARPARK_CODE_FAIL:
               return {
                 ...state,
                 loading: false,
                 error: action.payload,
                 carParkCode: [],
               };
    
    default:
      return state;
  }
};

export default Parkings;
