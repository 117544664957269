export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const GET_CURRENT_USER = "GET_CURRENT_USER"
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS"
export const GET_CURRENT_USER_FAIL = "GET_CURRENT_USER_FAIL"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"

export const GET_LANGUAGES = "GET_LANGUAGES"
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS"
export const GET_LANGUAGES_FAIL = "GET_LANGUAGES_FAIL"

//register owner
export const ADD_PARKING_OWNER_REQUEST = "ADD_PARKING_OWNER_REQUEST";
export const ADD_PARKING_OWNER_SUCCESS = "ADD_PARKING_OWNER_SUCCESS";
export const ADD_PARKING_OWNER_FAIL = "ADD_PARKING_OWNER_FAIL";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"

//update user perticular field

export const UPDATE_USER_DATA_FIELD = "UPDATE_USER_DATA_FIELD";