import React from "react";
import { Container } from "reactstrap";

import Welcomegreet from "components/Welcomegreet";
import Censorbottom from "./censorbottom";

function Censor() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Welcomegreet />
          <Censorbottom/>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Censor;
