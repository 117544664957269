import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import toastr from "toastr";
// Customer Redux States
import { GET_CUSTOMERS, GET_CUSTOMER, CHANGE_STATUS, UPDATE_OWNER_CLIENT_STATUS, GET_OWNER_PROFILE_STATUS } from "./actionTypes";
import {
  getCustomersFail,
  getCustomersSuccess,
  getCustomerFail,
  getCustomerSuccess,
} from "./actions";

import {
  getCustomers,
  getCustomer,
  changeStatus,
  updateOwnerStatusApi,
  ownerProfile
} from "helpers/backend_helper";

import * as actType from "./actions";

toastr.options = {
  newestOnTop: true,
  progressBar: true,
};

function* fetchCustomers({ payload: { data, callback } }) {
  console.log("Saga Called", data);
  try {
    const response = yield call(getCustomers, data);

    if (response.status == "failure") {
      return yield put(getCustomersFail(response.message));
    }

    yield put(getCustomersSuccess(response));
    callback && callback(response)
  } catch (error) {
    yield put(getCustomersFail(error));
  }
}

function* fetchCustomer({ payload }) {
  try {
    const response = yield call(getCustomer, payload);

    if (response.status == "failure") {
      return yield put(getCustomerFail(response.message));
    }

    yield put(getCustomerSuccess(response));
  } catch (error) {
    yield put(getCustomerFail(error));
  }
}

function* onChangeStatus({ payload }) {
  try {
    const response = yield call(changeStatus, payload);

    if (response.status == "failure") {
      return yield put(actType.changeStatusFail(response.message));
    }

    yield put(actType.changeStatusSuccess(response));
  } catch (error) {
    yield put(actType.changeStatusFail(error));
  }
}


function* updateOwnerStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(updateOwnerStatusApi, data);

    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(actType.updateStoreStatusFail(response.message));
    }

    yield put(actType.updateStoreStatusSuccess(response));
    toastr.success(response.message)
    callback && callback(response);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(actType.updateStoreStatusFail(error));
  }
}
function* getownerProfile({ payload: { data, callback } }) {
  try {
    const response = yield call(ownerProfile, data);

    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(actType.getownerProfileFail(response.message));
    }

    yield put(actType.getownerProfileSuccess(response));
    // toastr.success(response.message)
    callback && callback(response);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(actType.getownerProfileFail(error));
  }
}


function* customersSaga() {
  yield takeLatest(GET_CUSTOMERS, fetchCustomers);
  yield takeLatest(CHANGE_STATUS, onChangeStatus);
  yield takeLatest(UPDATE_OWNER_CLIENT_STATUS, updateOwnerStatus);
  yield takeLatest(GET_OWNER_PROFILE_STATUS, getownerProfile);
  yield takeLatest(GET_CUSTOMER, fetchCustomer);
}

export default customersSaga;
