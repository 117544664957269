import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAIL,
  ADD_PARKING_OWNER_FAIL,
  ADD_PARKING_OWNER_SUCCESS,
  ADD_PARKING_OWNER_REQUEST,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_USER_DATA_FIELD,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = (history, role) => {
  return {
    type: LOGOUT_USER,
    payload: { history, role },
  }
}

export const currentUser = () => {
  return {
    type: GET_CURRENT_USER,
  }
}

export const currentUserSuccess = user => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    payload: user,
  }
}

export const currentUserFail = error => {
  return {
    type: GET_CURRENT_USER_FAIL,
    payload: error,
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const getLanguages = () => ({
  type: GET_LANGUAGES,
})

export const getLanguagesSuccess = data => ({
  type: GET_LANGUAGES_SUCCESS,
  payload: data,
})

export const getLanguagesFail = error => ({
  type: GET_LANGUAGES_FAIL,
  payload: error,
})


export const addParkingOwnerRequest = (data, callback) => ({
  type: ADD_PARKING_OWNER_REQUEST,
  payload: { data, callback },
});

export const addParkingOwnerSuccess = (data) => ({
  type: ADD_PARKING_OWNER_SUCCESS,
  payload : data
});

export const addParkingOwnerFail = (error) => ({
  type: ADD_PARKING_OWNER_FAIL,
  payload: error,
});



export const updateProfileRequest = (data, callback) => {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload: { data, callback },
  }
}

export const updateProfileSuccess = data => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: data,
  }
}

export const updateProfileFail = error => {
  return {
    type: UPDATE_PROFILE_FAIL,
    payload: error,
  }
}

//update user data field 

export const updateUserField = (data) => {
  return {
    type: UPDATE_USER_DATA_FIELD,
    payload: {data},
  }
}
