import React from 'react'
import { Button, Col, Container, Row, Spinner } from 'reactstrap'

import Welcomegreet from 'components/Welcomegreet'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withTranslation } from 'react-i18next';
import {useEffect, useState} from "react";
import AddPaymentCardModal from "./AddPaymentCardModal";
import {getPayments,deletePayment} from "store/actions"
import PaymentCard from "./PaymentCard";
import ConfirmModal from "components/ConfirmModal";
import Welcomecards from 'components/Welcomecards';


function MyPaymentCards(props) {

    const [showAddPaymentCardModal,setShowAddPaymentCardModal] = useState(false);
    const [paymentCardId,setPaymentCardId] = useState(null);
    const [showConfirmModal,setShowConfirmModal] = useState(false);
 
   const {user:{_id : userId},payments,getPayments} = props;
 
 
 
   useEffect(()=>{
     if(userId){
       getPayments({userId});
     } 
    
   },[userId]);
    
   const onRemoveCardClick = (data) => {
     setPaymentCardId(data?._id);
     setShowConfirmModal(true);
 
   } 
 
   const onConfirmRemoveCard = () =>{
     const callback = () =>{
       setShowConfirmModal(false);
       setPaymentCardId(null);
   } 
    props.deletePayment({_id : paymentCardId},callback)
   }
 
  return (
    <React.Fragment>
    <div className="page-content">
         <Container fluid>
              <Welcomegreet/>
              <Welcomecards/>
            <div className='mt-5'>
              {showAddPaymentCardModal && <AddPaymentCardModal 
                    visible={showAddPaymentCardModal} 
                    onClose={()=>setShowAddPaymentCardModal(false)}
                    onFinish={()=>setShowAddPaymentCardModal(false)}
                    />}
          {showConfirmModal && (
               <ConfirmModal 
                  visible={showConfirmModal} 
                  onClose={()=>{
                      setShowConfirmModal(false)
                      setPaymentCardId(null);
                    }} 
                  onConfirm={()=>onConfirmRemoveCard()}
                  message = "Are you sure you want to delete this payment card?"
                  confirmLoader={props.loading}
                />
           )}
             <div className="d-flex justify-content-between flex-wrap">
                <h5 className='fillter_head pl-2'>{props.t("my_payment_cards")}</h5>    
                <Button  
                   color="success"
                   className="btn-rounded waves-effect waves-light mb-2 mr-2" 
                onClick={()=>setShowAddPaymentCardModal(true)}>
                    {props.t("add_card")}
                </Button>
             </div>
             {props.loadingPayments && 
                <Row className="d-flex justify-content-center">
                  <Spinner animation="border" variant="primary" />
                </Row>
            }
             {!props.loadingPayments
             && (
             <Row className="mt-5 mb-5">
               {(payments || []).map((item)=>(
                 <Col xs={24} sm={24} md={4} lg={4} className="mt-2 mb-2">
                    <PaymentCard
                        data={item}
                        onRemoveClick={(data)=>onRemoveCardClick(data)}
                      />
                 </Col>     
               ))}
             </Row>
             )}
</div>
         </Container>
    </div>
    </React.Fragment>
  )
}


const mapStateToProps = ({Payment, Login }) => ({
    user : Login.user || {},
    authToken : Login.authToken || null,
    loading : Payment.loading, 
    loadingPayments : Payment.loadingPayments,
    payments : Payment.payments, 
    error : Payment.error 
  });
  
  const mapDispatchToProps = (dispatch) => ({
    getPayments: (data) => dispatch(getPayments(data)),
    deletePayment: (data,callback) => dispatch(deletePayment(data,callback)) 
  });
  
  export default withRouter( connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyPaymentCards)));
