import { call, put, takeEvery } from "redux-saga/effects"

// ContentPage Redux States
import {
  GET_CONTENT_PAGES,
  ADD_CONTENT_PAGE,
  GET_CONTENT_PAGE,
  PUT_CONTENT_PAGE,
  DELETE_CONTENT_PAGE,
  PUT_CONTENT_PAGES_STATUS,
} from "./actionTypes"
import {
  getContentPagesFail,
  getContentPagesSuccess,
  addContentPageFail,
  addContentPageSuccess,
  getContentPageFail,
  getContentPageSuccess,
  putContentPageFail,
  putContentPageSuccess,
  deleteContentPageFail,
  deleteContentPageSuccess,
  putContentPagesStatusFail,
  putContentPagesStatusSuccess,
} from "./actions"

import {
  getContentPages,
  postContentPage,
  getContentPage,
  putContentPage,
  deleteContentPage,
  putContentPagesStatus,
  getContentPageByType,
} from "helpers/backend_helper"


function* fetchContentPages({ payload }) {
console.log("hjhhkjhkj")

  try {
    const response = yield call(getContentPages, payload)

    if (response.status == "failure") {
      console.log("jsfdkkjdsf", response)
      return yield put(getContentPagesFail(response.message))
    }
    console.log("kljfdsjjdfs", response)

    yield put(getContentPagesSuccess(response))
  } catch (error) {
    console.log("error", error)
    yield put(getContentPagesFail(error))
  }
}

function* onAddNewContentPage({ payload: { contentPage, history } }) {
  try {
    const response = yield call(postContentPage, contentPage)

    if (response.status == "failure") {
      window.scrollTo(0, 0)

      return yield put(addContentPageFail(response.message))
    }

    history && history.replace("/content-pages")

    yield put(addContentPageSuccess())
  } catch (error) {
    yield put(addContentPageFail("Internal Error!"))
  }
}

function* fetchContentPage({ payload }) {
  try {
    const response = yield call(getContentPageByType, payload)

    if (response.status == "failure") {
      return yield put(getContentPageFail(response.message))
    }

    yield put(getContentPageSuccess(response.data))
  } catch (error) {
    yield put(getContentPageFail(error))
  }
}

function* onPutContentPage({ payload: { data, history } }) {
  try {
    const response = yield call(putContentPage, data)

    if (response.status == "failure") {
      return yield put(putContentPageFail(response.message))
    }

    history && history.replace("/content-pages")

    yield put(putContentPageSuccess())
  } catch (error) {
    yield put(putContentPageFail("Internal Error!"))
  }
}

function* onDeleteContentPage({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteContentPage, data)

    if (response.status == "failure") {
      return yield put(deleteContentPageFail(response.message))
    }

    yield put(deleteContentPageSuccess())
    callback && callback()
  } catch (error) {
    yield put(deleteContentPageFail("Internal Error!"))
  }
}

function* onPutContentPagesStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putContentPagesStatus, data)

    if (response.status == "failure") {
      return yield put(putContentPagesStatusFail(response.message))
    }

    yield put(putContentPagesStatusSuccess())
    callback && callback()
  } catch (error) {
    yield put(putContentPagesStatusFail("Internal Error!"))
  }
}

function* ContentPagesSaga() {
  yield takeEvery(GET_CONTENT_PAGES, fetchContentPages)
  yield takeEvery(ADD_CONTENT_PAGE, onAddNewContentPage)
  yield takeEvery(GET_CONTENT_PAGE, fetchContentPage)
  yield takeEvery(PUT_CONTENT_PAGE, onPutContentPage)
  yield takeEvery(DELETE_CONTENT_PAGE, onDeleteContentPage)
  yield takeEvery(PUT_CONTENT_PAGES_STATUS, onPutContentPagesStatus)
}

export default ContentPagesSaga
