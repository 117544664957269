export const PAYMENT_API_FAIL = "PAYMENT_API_FAIL"

// Faqs
export const GET_PAYMENTS = "GET_PAYMENTS"
export const GET_PAYMENTS_FAIL = "GET_PAYMENTS_FAIL"
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS"

// Add prmomo
export const ADD_PAYMENT = "ADD_PAYMENT"
export const ADD_PAYMENT_FAIL = "ADD_PAYMENT_FAIL"
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS"

// Get prmomo
export const GET_PAYMENT = "GET_PAYMENT"
export const GET_PAYMENT_FAIL = "GET_PAYMENT_FAIL"
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS"

// update prmomo
export const PUT_PAYMENT = "PUT_PAYMENT"
export const PUT_PAYMENT_FAIL = "PUT_PAYMENT_FAIL"
export const PUT_PAYMENT_SUCCESS = "PUT_PAYMENT_SUCCESS"

// Delete prmomo
export const DELETE_PAYMENT = "DELETE_PAYMENT"
export const DELETE_PAYMENT_FAIL = "DELETE_PAYMENT_FAIL"
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS"

// Update Multi Faq Status
export const PUT_PAYMENTS_STATUS = "PUT_PAYMENTS_STATUS"
export const PUT_PAYMENTS_STATUS_FAIL = "PUT_PAYMENTS_STATUS_FAIL"
export const PUT_PAYMENTS_STATUS_SUCCESS = "PUT_PAYMENTS_STATUS_SUCCESS"
