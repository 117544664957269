import * as CONST from "./actionTypes";

export const getParkingStructuresRequest = (data, callback) => ({
  type: CONST.GET_PARKING_STRUCTURES_REQUEST,
  payload: { data, callback },
});

export const getParkingStructuresSuccess = (data) => ({
  type: CONST.GET_PARKING_STRUCTURES_SUCCESS,
  payload : data
});

export const getParkingStructuresFail = (error) => ({
  type: CONST.GET_PARKING_STRUCTURES_FAIL,
  payload: error,
});

export const getParkingStructureRequest = (data, callback) => ({
    type: CONST.GET_PARKING_STRUCTURE_REQUEST,
    payload: { data, callback },
  });
  
  export const getParkingStructureSuccess = (data) => {
    return{
    type: CONST.GET_PARKING_STRUCTURE_SUCCESS,
    payload : data
  }};
  
  export const getParkingStructureFail = (error) => ({
    type: CONST.GET_PARKING_STRUCTURE_FAIL,
    payload: error,
  });

export const addParkingStructureRequest = (data, callback) => ({
    type: CONST.ADD_PARKING_STRUCTURE_REQUEST,
    payload: { data, callback },
  });
  
  export const addParkingStructureSuccess = (data) => ({
    type: CONST.ADD_PARKING_STRUCTURE_SUCCESS,
    payload : data
  });
  
  export const addParkingStructureFail = (error) => ({
    type: CONST.ADD_PARKING_STRUCTURE_FAIL,
    payload: error,
  });

export const updateParkingStructureRequest = (data, callback) => ({
    type: CONST.UPDATE_PARKING_STRUCTURE_REQUEST,
    payload: { data, callback },
  });
  
  export const updateParkingStructureSuccess = (data) => ({
    type: CONST.UPDATE_PARKING_STRUCTURE_SUCCESS,
    payload : data
  });
  
  export const updateParkingStructureFail = (error) => ({
    type: CONST.UPDATE_PARKING_STRUCTURE_FAIL,
    payload: error,
  });

export const deleteParkingStructureRequest = (data, callback) => ({
    type: CONST.DELETE_PARKING_STRUCTURE_REQUEST,
    payload: { data, callback },
  });
  
  export const deleteParkingStructureSuccess = (data) => ({
    type: CONST.DELETE_PARKING_STRUCTURE_SUCCESS,
    payload : data
  });
  
  export const deleteParkingStructureFail = (error) => ({
    type: CONST.DELETE_PARKING_STRUCTURE_FAIL,
    payload: error,
  });
  

  //permit cards

export const getPermitCardsRequest = (data, callback) => ({
  type: CONST.LIST_PERMIT_CARDS_REQUEST,
  payload: { data, callback },
});

export const getPermitCardsSuccess = (data) => ({
  type: CONST.LIST_PERMIT_CARDS_SUCCESS,
  payload : data
});

export const getPermitCardsFail = (error) => ({
  type: CONST.LIST_PERMIT_CARDS_FAIL,
  payload: error,
});


export const assignPermitCardRequest = (data, callback) => ({
  type: CONST.ASSIGN_PERMIT_CARD_REQUEST,
  payload: { data, callback },
});

export const assignPermitCardSuccess = (data) => ({
  type: CONST.ASSIGN_PERMIT_CARD_SUCCESS,
  payload : data
});

export const assignPermitCardFail = (error) => ({
  type: CONST.ASSIGN_PERMIT_CARD_FAIL,
  payload: error,
});


export const removePermitCardRequest = (data, callback) => ({
  type: CONST.REMOVE_PERMIT_CARD_REQUEST,
  payload: { data, callback },
});

export const removePermitCardSuccess = (data) => ({
  type: CONST.REMOVE_PERMIT_CARD_SUCCESS,
  payload : data
});

export const removePermitCardFail = (error) => ({
  type: CONST.REMOVE_PERMIT_CARD_FAIL,
  payload: error,
});


export const getUserVehicalsRequest = (data, callback) => ({
  type: CONST.GET_USER_VEHICALS_REQUEST,
  payload: { data, callback },
});

export const getUserVehicalsSuccess = (data) => ({
  type: CONST.GET_USER_VEHICALS_SUCCESS,
  payload : data
});

export const getUserVehicalsFail = (error) => ({
  type: CONST.GET_USER_VEHICALS_FAIL,
  payload: error,
});


export const getCarParkCodesRequest = (data, callback) => ({
  type: CONST.GET_CARPARK_CODE_REQUEST,
  payload: { data, callback },
});

export const getCarParkCodesSuccess = (data) => ({
  type: CONST.GET_CARPARK_CODE_SUCCESS,
  payload : data
});

export const getCarParkCodesFail = (error) => ({
  type: CONST.GET_CARPARK_CODE_FAIL,
  payload: error,
});