import { CountryCodeSelector } from "components/Common/CountryCodeSelector";
import UserSelector from "components/Common/UserSelector";
import UserVehicalSelector from "components/Common/UserVehicalSelector";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { assignPermitCardRequest } from "store/actions";

const AssignPermitCard = ({ data, onFinish, ...props }) => {
  const { loading } = useSelector(({ customers }) => customers);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedVehical, setSelectedVehical] = useState(null);
  const { user } = useSelector(({ Login }) => Login)
  const dispatch = useDispatch();
  const [fields, setFields] = useState({
    vehicalId: "",
    parkingOwnerId: user?._id,
    cardNumber: ""
  });


  const onFieldsChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value })
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = { ...fields, parkingOwnerId: user?._id };
    console.log("payload", payload);


    dispatch(assignPermitCardRequest(payload, () => {
      props.onhide();
      onFinish();
    }))
  }

  const onChangeUserHandler = (data) => {
    setFields(prev => ({ ...prev, userId: data?.value }));
    setSelectedUser(data);
  }

  const onChangeVehicalHandler = (data) => {
    setFields(prev => ({ ...prev, vehicalId: data?.value }));
    setSelectedVehical(data);
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="addslot_modalfrom"
      centered
    >
      <Modal.Body>
        <div className="Add_slot_form">
          <Form onSubmit={onSubmit}>
            <div className="form_header position-relative">
              <a
                href="javascript:void(0)"
                className="close_btn"
                onClick={props.onhide}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="21"
                  viewBox="0 0 24 21"
                  fill="none"
                >
                  <path
                    d="M0.94 21L10.12 8.25L10.09 12.48L1.27 -1.43051e-06H7.96L13.6 8.13L10.75 8.16L16.3 -1.43051e-06H22.72L13.9 12.24V8.04L23.2 21H16.36L10.63 12.45H13.36L7.72 21H0.94Z"
                    fill="#2E3A59"
                  />
                </svg>
              </a>
              <h3>{props.t("assign_permit_card")}</h3>
            </div>

            <div className="form_layout mt-5">

              <Row>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("user")}</Label>
                    <UserSelector
                      placeholder={props.t("select")}
                      isMulti={false}
                      defaultSelected={selectedUser}
                      onChangeHandler={onChangeUserHandler}
                    />

                  </FormGroup>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("vehical")}</Label>
                    <UserVehicalSelector
                      placeholder={props.t("select")}
                      isMulti={false}
                      userId={selectedUser?.value}
                      defaultSelected={selectedVehical}
                      onChangeHandler={onChangeVehicalHandler}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("permit_card")}</Label>
                    <Input
                      type="text"
                      name="cardNumber"
                      id="exampleEmail"
                      placeholder="Enter permit card number."
                      onChange={(e) => onFieldsChange(e)}
                      value={fields.email}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("sensor_number")}</Label>
                    <Input
                      type="text"
                      name="sensorNumber"
                      id="exampleEmail"
                      placeholder="Enter sensor number."
                      onChange={(e) => onFieldsChange(e)}
                      value={fields.email}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <Button type="submit" className="add_slot mt-5 w-100">
                    {loading ?
                      <Spinner className="mx-1" size="sm" color="light" />
                      : null}
                    {props.t("save")}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(AssignPermitCard);
