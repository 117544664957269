import {
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  REMOVE_SUBSCRIPTIONS_REQUEST,
  REMOVE_SUBSCRIPTIONS_SUCCESS,
  REMOVE_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTION_PLAN_FAIL,
  GET_SUBSCRIPTION_PLAN_SUCCESS,
  GET_SUBSCRIPTION_PLAN_REQUEST,
  GET_SUBSCRIPTIONS_PLAN_FAIL,
  GET_SUBSCRIPTIONS_PLAN_SUCCESS,
  GET_SUBSCRIPTIONS_PLAN_REQUEST,
  DELETE_SUBSCRIPTION_PLAN_FAIL,
  DELETE_SUBSCRIPTION_PLAN_SUCCESS,
  DELETE_SUBSCRIPTION_PLAN_REQUEST,
  UPDATE_SUBSCRIPTION_PLAN_FAIL,
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
  UPDATE_SUBSCRIPTION_PLAN_REQUEST,
  ADD_SUBSCRIPTION_PLAN_FAIL,
  ADD_SUBSCRIPTION_PLAN_SUCCESS,
  ADD_SUBSCRIPTION_PLAN_REQUEST,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
  GET_SUBSCRIPTION_USERS_REQUEST,
  GET_SUBSCRIPTION_USERS_SUCCESS,
  GET_SUBSCRIPTION_USERS_FAIL,
  ASSIGN_SUBSCRIPTION_TO_USER_REQUEST,
  ASSIGN_SUBSCRIPTION_TO_USER_SUCCESS,
  ASSIGN_SUBSCRIPTION_TO_USER_FAIL,
  BUY_OWNER_SUBSCRIPTION_PLAN_FAIL,
  BUY_OWNER_SUBSCRIPTION_PLAN_REQUEST,
  BUY_OWNER_SUBSCRIPTION_PLAN_SUCCESS,
} from "./actionTypes";

const { data, ...authUser } = JSON.parse(
  localStorage.getItem("authUser") || "{}"
);

const initialState = {
  loading: false,
  subscriptions: [],
  error: "",
  totalSubscriptions: 0,
  subscriptionPlans: [],
  totalSubscriptionPlans: 0,
  addUpdateLoading: false,
  singleSubscriptionPlan: null,
  loadingCancelSubscription: false,
  subscriptionUsers: [],
  totalSubscriptionUsers: 0,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptions: action.payload.data,
        totalSubscriptions: action.payload.totalCounts,
      };
    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REMOVE_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptions: state.subscriptions.filter(
          (item) => item !== action.payload?._id
        ),
        totalSubscriptions: state.totalSubscriptions - 1,
      };
    case REMOVE_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ADD_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        addUpdateLoading: true,
      };

    case ADD_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        addUpdateLoading: false,
        error: "",
      };

    case ADD_SUBSCRIPTION_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateLoading: false,
      };

    /* Update */
    case UPDATE_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        addUpdateLoading: true,
      };

    case UPDATE_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        addUpdateLoading: false,
        error: "",
      };

    case UPDATE_SUBSCRIPTION_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateLoading: false,
      };

    /* Delete */
    case DELETE_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        addUpdateLoading: true,
      };

    case DELETE_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        addUpdateLoading: false,
        error: "",
      };

    case DELETE_SUBSCRIPTION_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        addUpdateLoading: false,
      };
    /* Get */
    case GET_SUBSCRIPTIONS_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_SUBSCRIPTIONS_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionPlans: action.payload.data,
        totalSubscriptionPlans: action.payload.totalCount,
        error: "",
      };

    case GET_SUBSCRIPTIONS_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get */
    case GET_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        singleSubscriptionPlan: action.payload.data,
        error: "",
      };

    case GET_SUBSCRIPTION_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    //cancel subscription
    case CANCEL_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loadingCancelSubscription: true,
      };

    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loadingCancelSubscription: false,
        error: "",
      };

    case CANCEL_SUBSCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingCancelSubscription: false,
      };
    case GET_SUBSCRIPTION_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBSCRIPTION_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionUsers: action.payload.data,
        totalSubscriptionUsers: action.payload.totalCounts,
      };
    case GET_SUBSCRIPTION_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ASSIGN_SUBSCRIPTION_TO_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ASSIGN_SUBSCRIPTION_TO_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionUsers: action.payload.data,
      };
    case ASSIGN_SUBSCRIPTION_TO_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case BUY_OWNER_SUBSCRIPTION_PLAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BUY_OWNER_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionUsers: action.payload.data,
      };
    case BUY_OWNER_SUBSCRIPTION_PLAN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
  }
  return state;
};

export default login;
