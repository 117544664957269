import { call, put, retry, takeEvery, takeLatest } from "redux-saga/effects";
import toastr from "toastr";
// Login Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "store/actions";
import * as API from "helpers/backend_helper";

import { updateToken } from "helpers/api_helper";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(API.postLogin, {
      email: user.email,
      password: user.password,
    });

    if (response.status == "failure") {
      return yield put(ACTIONS.apiError(response.message));
    }
    console.log(JSON.stringify(response));
    localStorage.setItem("authUser", JSON.stringify(response));
    updateToken(response.data.token);
    yield put(ACTIONS.loginSuccess(response));
    history.push("/");
    window.location.reload()
  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}

function* currentUser() {
  try {
    const response = yield call(API.getCurrentUser);

    if (response.status == "failure") {
      return yield put(ACTIONS.currentUserFail(response.message));
    }

    yield put(ACTIONS.currentUserSuccess(response));
  } catch (error) {
    yield put(ACTIONS.currentUserFail("Server Error"));
  }
}

function* logoutUser({ payload: { history, role } }) {
  try {
    localStorage.removeItem("authUser");
    yield put(ACTIONS.logoutUserSuccess());
    history.push("/login");

  } catch (error) {
    yield put(ACTIONS.apiError(error));
  }
}


function* addParkingOwnerReq({ payload: { data, callback } }) {
  try {
    const response = yield call(API.addParkingOwnerApi, data);

    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(ACTIONS.addParkingOwnerFail(response.message));
    }
    localStorage.setItem("authUser", JSON.stringify(response));
    updateToken(response.data.token);

    yield put(ACTIONS.addParkingOwnerSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(ACTIONS.addParkingOwnerFail(error));
  }
}


function* updateProfileReq({ payload: { data, callback } }) {
  try {
    const response = yield call(API.putProfile, data)
    if (response.status === "failure") {
      toastr.error(response.message)
      return yield put(ACTIONS.updateProfileFail(response.message))
    }

    yield put(ACTIONS.updateProfileSuccess(response));
    localStorage.setItem("authUser", JSON.stringify(response));
    updateToken(response.data.token);

    toastr.success("Profile updated successfully!");
    callback && callback(response.data);
  } catch (error) {
    toastr.error("Internal Server Error")
    yield put(ACTIONS.updateProfileFail("Internal Server Error"))
  }
}

function* authSaga() {
  yield takeEvery(TYPES.LOGIN_USER, loginUser);
  yield takeEvery(TYPES.GET_CURRENT_USER, currentUser);
  yield takeEvery(TYPES.LOGOUT_USER, logoutUser);
  yield takeLatest(TYPES.ADD_PARKING_OWNER_REQUEST, addParkingOwnerReq),
    yield takeLatest(TYPES.UPDATE_PROFILE_REQUEST, updateProfileReq)
}

export default authSaga;
