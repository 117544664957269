import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

// imges
import Logo from "../../../assets/images/logo.png";

const Subscriptionsingle = () => {
  const history = useHistory();
  return (
    <section id="Subcriptionsingle" className="Subscriptionsingle">
      <Container>
        <div className="inner_containersubsription">
          <div className="top_barsubscritption">
            <a href="javascript:void(0)" onClick={() => history.goBack()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M15 25L5 15L15 5L16.7812 6.75L9.78125 13.75H25V16.25H9.78125L16.7812 23.25L15 25Z"
                  fill="black"
                />
              </svg>
            </a>

            <img src={Logo} alt="" className="img-fluid" />

            <h3>Choose a subscription plan</h3>
          </div>

          <div className="bottom_subcardcard mt-5">
            <Row>
              <Col lg={4} md={6} sm={12}>
                <div className="card_coommnsub monthly_Card">
                  <h5>Monthly</h5>
                  <h1>$21</h1>
                  <ul>
                    <li>1 User</li>
                    <li>5 Projects</li>
                    <li>5 GB Storage</li>
                    <li>Unlimited Message</li>
                  </ul>
                  <Button className="btn_card_comon month_red">Get Start</Button>
                </div>
              </Col>

              <Col lg={4} md={6} sm={12}>
                <div className="card_coommnsub threemonthly_Card">
                <h5>3 Monthly</h5>
                  <h1>$41</h1>
                  <ul>
                    <li>1 User</li>
                    <li>5 Projects</li>
                    <li>5 GB Storage</li>
                    <li>Unlimited Message</li>
                  </ul>
                  <Button className="btn_card_comon threemonth_btn">Get Start</Button>
                </div>
              </Col>

              <Col lg={4} md={6} sm={12}>
                <div className="card_coommnsub sixmonthly_Card">
                <h5>6 Monthly</h5>
                  <h1>$61</h1>
                  <ul>
                    <li>1 User</li>
                    <li>5 Projects</li>
                    <li>5 GB Storage</li>
                    <li>Unlimited Message</li>
                  </ul>
                  <Button className="btn_card_comon sixmoth_btn">Get Start</Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Subscriptionsingle;
