import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Form } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

import { Col, Container, Row, Spinner } from "reactstrap";

import { getCustomers } from "store/actions";
import UsersColumns from "./Columns";
import UsersConfirmModal from "./ConfirmModal";


const Users = (props) => {
  const {
    history,
    users,
    user : authUser,
    totalUsers,
    onGetUsers,
    loading,
  } = props;
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });

  const [filter, setFilter] = useState({
    orderBy: "createdAt",
    order: -1,
    page: 1,
    limit: 10,
    ownerId : authUser?._id
  });
  const [searchText, setSearchText] = useState("");
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalUsers,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    if (authUser?._id) {
      onGetUsers({ ...filter,ownerId : authUser?._id });
    }
  }, [JSON.stringify(filter),authUser]);


  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (userId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  const removeConfirm = () => {
    const { userId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetUsers({ ...filter, ownerId: authUser?._id });
    };

    setConfirmModal({ isOpen: !isOpen, userId: null });
    onDeleteUser({ _id: userId }, onComplete);
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText }));
  };


  return (
    <React.Fragment>
      <UsersConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />
    
              <div className="latest_cust_table">
              <h2 class="fillter_head pl-2">{props.t("latest")} {props.t("customers")}</h2>                 
          <Row>
            <Col xs="12">
            
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={users}
                        columns={UsersColumns(props.t, "text")}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content mt-3">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
            </Col>
          </Row>
              </div>
      
    </React.Fragment>
  );
};



const mapStateToProps = ({ customers,Login }) => ({
  loading: customers.loading,
  users: customers.customers,
  totalUsers: customers.totalCustomers,
  user : Login.user
});

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUsers: (data) => {
      console.log(data);
      dispatch(getCustomers(data));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users))
);
