import * as TYPES from "./actionTypes";

/* Get stakes */
export const getStakes = (data) => ({
  type: TYPES.GET_STAKES,
  payload: data,
});

export const getStakesSuccess = (stakes) => ({
  type: TYPES.GET_STAKES_SUCCESS,
  payload: stakes,
});

export const getStakesFail = (error) => ({
  type: TYPES.GET_STAKES_FAIL,
  payload: error,
});
/* Get Stakes END */

/* Stats */
// export const getStakeStats = (data) => ({
//   type: TYPES.GET_STAKE_STATS,
//   payload: data,
// });

// export const getStakeStatsSuccess = (data) => ({
//   type: TYPES.GET_STAKE_STATS_SUCCESS,
//   payload: data,
// });

// export const getStakeStatsFail = (error) => ({
//   type: TYPES.GET_STAKE_STATS_FAIL,
//   payload: error,
// });
/* Stats END */
