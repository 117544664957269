import {
  PAYMENT_API_FAIL,
  GET_PAYMENTS,
  GET_PAYMENTS_FAIL,
  GET_PAYMENTS_SUCCESS,
  ADD_PAYMENT,
  ADD_PAYMENT_FAIL,
  ADD_PAYMENT_SUCCESS,
  GET_PAYMENT,
  GET_PAYMENT_FAIL,
  GET_PAYMENT_SUCCESS,
  PUT_PAYMENT,
  PUT_PAYMENT_FAIL,
  PUT_PAYMENT_SUCCESS,
  DELETE_PAYMENT,
  DELETE_PAYMENT_FAIL,
  DELETE_PAYMENT_SUCCESS,
  PUT_PAYMENTS_STATUS,
  PUT_PAYMENTS_STATUS_FAIL,
  PUT_PAYMENTS_STATUS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  payments: [],
  totalPayments: 0,
  error: "",
  loading: false,
  loadingPayments:false,
  payment: {},
}

const Payments = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Payments */
    case GET_PAYMENTS:
      return {
        ...state,
        loadingPayments: true,
        error:""
      }

    case GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload.data || [],
        totalPayments: action.payload.totalCount || 0,
        loadingPayments: false,
        error: "",
      }

    case GET_PAYMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingPayments: false,
      }
    /* Get Payments END */

    /* Add Payment */
    case ADD_PAYMENT:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case ADD_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        error: "",
        payments: [action.payload.data,...state.payments],
        loading: false,
      }
    /* Add Payment END */

    /* Get Payment */
    case GET_PAYMENT:
      return {
        ...state,
        error: "",
        loading: true,
        payment: {},
      }

    case GET_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        payment: {},
      }

    case GET_PAYMENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        payment: action.payload,
      }
    /* Add Payment END */

    /* Update Payment */
    case PUT_PAYMENT:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_PAYMENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update Payment END */

    /* Delete Payment */
    case DELETE_PAYMENT:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case DELETE_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        error: "",
        payments: state.payments.filter((item)=>(item?._id !== action.payload?._id)),
        loading: false,
      }
    /* Delete Payment END */

    /* Update Payments Status */
    case PUT_PAYMENTS_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      }

    case PUT_PAYMENTS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case PUT_PAYMENTS_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      }
    /* Update Payments Status END */

    case PAYMENT_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Payments
