import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";


import Welcomegreet from "components/Welcomegreet";


//i18n
import { withTranslation } from "react-i18next";

import { Col, Container, Row, Spinner } from "reactstrap";
import SearchInput from "components/Common/SearchInput";

import { getSensorsRequest, removeSensorRequest, importSensorRequest } from "store/actions";
import UsersColumns from "./Columns";
import UsersConfirmModal from "./ConfirmModal";
import Welcomecards from "components/Welcomecards";

import ImportModal from "./ImportModal";
import toastr from "toastr";

const Censors = (props) => {
  const {
    history,
    sensors,
    totalSensors,
    getSensorsRequest,
    removeSensorRequest,
    onImportSensorRequest,
    loading,
    user
  } = props;
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });

  const [importModal, setImportModal] = useState({
    isOpen: false,
  });

  const [filter, setFilter] = useState({
    orderBy: "createdAt",
    order: -1,
    page: 1,
    limit: 10,
    ownerId: user?._id
  });
  const [searchText, setSearchText] = useState("");
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalSensors,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    getSensorsRequest({ ...filter });
  }, [JSON.stringify(filter)]);


  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (userId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  const refreshButton = () => {
    getSensorsRequest({ ...filter });
  }



  const removeConfirm = () => {
    const { userId, isOpen } = confirmModal;

    const onComplete = () => {
      getSensorsRequest(filter);
    };

    setConfirmModal({ isOpen: !isOpen, userId: null });
    removeSensorRequest({ id: userId }, onComplete);
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText }));
  };

  // Function to convert data to CSV and trigger download
  const convertAndDownloadCSV = () => {
    // Convert the data to a CSV string

    let newSensors = sensors.map((sensor) => {
      return {
        'Slot Number': sensor.carParkSlotNumber,
        'Sensor Number': sensor.number,
        'Carpark Id': sensor.carpark_id,
        'Serial Number': sensor.sensor_info.serial_number,
        'Floor': sensor.floor,
        'status': sensor.status,
        'Permit Card': sensor.parking_card,
        'Carpark Code': sensor.carpark_code,
        'Status Duration (Hours)': sensor?.duration,
        'DB ID': sensor.id,
        'Temperature': sensor?.sensor_info?.temperature,
        'Battery Volatage': sensor?.sensor_info.battery_voltage,
        'Battery': sensor?.sensor_info.battery_capacity,
        'Company': sensor?.companyId?.company_name,
        'Parking Name': sensor.parkingId?.building_name,
        'Network Info Type': sensor?.sensor_info.network_info?.type,
        'Network Info RSSI': sensor?.sensor_info.network_info?.rssi,
        'Network Info On Air': sensor?.sensor_info.network_info?.on_air,
      }
    })

    const csv = convertDataToCSV(newSensors);

    // Create a Blob object for the CSV data
    const blob = new Blob([csv], { type: 'text/csv' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = `${new Date().getTime()}.csv`;

    // Trigger the download
    a.click();

    // Clean up resources
    window.URL.revokeObjectURL(url);
  };

  // Function to convert data to CSV format
  const convertDataToCSV = (data) => {
    // Implement the logic to convert your data into CSV format here
    // For a more complex data structure, you may want to use a library like 'json2csv'
    const header = Object.keys(data[0]).join(',') + '\n';
    const csvData = data.map((row) => Object.values(row).join(',')).join('\n');
    return header + csvData;
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [callApi, setCallApi] = useState(0);

  const handleFileChange = (event) => {
    console.log(event);
    // Get the selected file from the input
    const file = event.target.files[0];

    if (file && file.name.endsWith('.csv')) {
      setSelectedFile(file);
    } else {
      return toastr.error("Please select a valid CSV file");

    }

  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return toastr.error("Please select a file");

    }

    // Create a FormData object to append the file
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('parkingOwnerId', user?._id)
    onImportSensorRequest(formData);
    setUploadProgress(true);

    setTimeout(() => {
      toastr.success("File uploaded.");
      setUploadProgress(true);
      setSelectedFile();
      setImportModal(false);
      setCallApi(+ 1);
      getSensorsRequest(filter);

    }, 3000)
  }
  const toggleImportModal = () => {
    setImportModal((prevState) => ({ isOpen: !prevState.isOpen }));
  };


  return (
    <React.Fragment>
      <UsersConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <ImportModal
        isOpen={importModal.isOpen}
        toggle={toggleImportModal}
        onConfirm={removeConfirm}
        handleUpload={handleUpload}
        handleFileChange={handleFileChange}
        uploadProgress={uploadProgress}
        selectedFile={selectedFile}
      />

      <div className="page-content">
        <Container fluid>
          <Welcomegreet />
          <Welcomecards />
          <div className="fillter_ssection mt-5">
            <Form>
              <div className="latest_cust_table mt-4">
                {/* <Newlist/> */}

                <Row>
                  <Col xs="12">

                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          data={sensors}
                          columns={UsersColumns(toggleConfirmModal, props.t)}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col lg={6} md={12} sm={12}>
                                  <div className="custom-flex-sm d-flex align-items-center">

                                    <div className="search d-flex align-items-center">
                                      <div className="pl-3">
                                        <SearchInput
                                          {...toolkitProps.searchProps}
                                          triggerSearch={onSearch}
                                          placeholder={props.t("search")}
                                          searchText={searchText}
                                        />
                                      </div>

                                      <div className="pl-3">
                                        <span onClick={toggleImportModal} style={{ fontSize: '17px', cursor: "pointer" }}>
                                          <i className="fas fa-file-import"></i> Import Data
                                        </span>
                                      </div>

                                      <div className="pl-3">
                                        <span onClick={convertAndDownloadCSV} style={{ fontSize: '17px', cursor: "pointer" }}>
                                          <i className="fas fa-file-export"></i> Export Data
                                        </span>
                                      </div>

                                    </div>
                                  </div>
                                </Col>

                                <Col
                                  className="mb-3"
                                  xs={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                >
                                  <div className="text-sm-left text-md-right">

                                    <Button
                                      type="button"
                                      color="success"
                                      className="add_slot mb-2 mr-2"
                                      onClick={() =>
                                        history.push("/sensors/add")
                                      }
                                    >
                                      <i className="mdi mdi-plus mr-1" />
                                      {props.t("add_slots")}
                                    </Button>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="add_slot mb-2 mr-2"
                                      onClick={() =>
                                        refreshButton()
                                      }
                                    >
                                      {props.t("refresh")}
                                    </Button>

                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive spinner-content mt-3">
                                    <BootstrapTable
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                      defaultSorted={[
                                        {
                                          dataField: "createdAt",
                                          order: "desc",
                                        },
                                      ]}
                                      sort={{
                                        sortCaret: (order) =>
                                          order === "asc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-up" />
                                            </span>
                                          ) : order === "desc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-down" />
                                            </span>
                                          ) : null,
                                      }}
                                    />

                                    {loading && (
                                      <div className="spinner">
                                        <Spinner color="primary" />
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};



const mapStateToProps = ({ Sensors, Login }) => ({
  loading: Sensors.loading,
  sensors: Sensors.sensors,
  totalSensors: Sensors.totalSensors,
  user: Login.user
});

const mapDispatchToProps = (dispatch) => {
  return {
    onImportSensorRequest: (data) => {
      dispatch(importSensorRequest(data));
    },
    getSensorsRequest: (data) => {
      console.log(data);
      dispatch(getSensorsRequest(data));
    },
    removeSensorRequest: (data, callback) => {
      dispatch(removeSensorRequest(data, callback))
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Censors))
);
