import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAIL,
  ADD_PARKING_OWNER_FAIL,
  ADD_PARKING_OWNER_SUCCESS,
  ADD_PARKING_OWNER_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_FAIL,
  UPDATE_USER_DATA_FIELD,
} from "./actionTypes"

const { data, ...authUser } = JSON.parse(
  localStorage.getItem("authUser") || "{}",
)

const initialState = {
  meLoading: false,
  isMeFailed: false,
  error: "",
  loading: false,
  user: data || {},
  authToken: data?.token || null,
  languages: [],
  timezones: [],
  countries: [],
  currencies: [],
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        error: "",
        loading: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: "",
        user: action.payload.data,
        authToken: action.payload.data.token,
        loading: false,
      }
    case ADD_PARKING_OWNER_REQUEST:
      return {
        ...state,
        loading: true,
        error : "",
      };

    case ADD_PARKING_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
        authToken: action.payload.data.token,
      };

    case ADD_PARKING_OWNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };     
    case GET_CURRENT_USER:
      return {
        ...state,
        error: "",
        meLoading: true,
        isMeFailed: false,
      }
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        error: "",
        user: action.payload.data,
        meLoading: false,
        isMeFailed: false,
      }

    case GET_CURRENT_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        isMeFailed: true,
        meLoading: false,
      }
    case LOGOUT_USER:
      return { ...state }
    case LOGOUT_USER_SUCCESS:
      return { ...state, user: {}, authToken: null }
    case API_ERROR:
      return { ...state, error: action.payload, loading: false }

    case GET_LANGUAGES_SUCCESS:
      return { ...state, ...action.payload }
      case UPDATE_PROFILE_REQUEST:
        return {
          ...state,
          error: "",
          loading : true
        }
      case UPDATE_PROFILE_SUCCESS:
        return {
          ...state,
          error: "",
          loading : false,
          user: {...state.user , ...action.payload.data} 
         }
  
      case UPDATE_PROFILE_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
      
      case UPDATE_USER_DATA_FIELD:
        return {
          ...state,
          user: {...state.user , ...action.payload.data} 
        }  
    default:
      return { ...state }
  }
  return state
}

export default login
