import { call, put, takeEvery } from "redux-saga/effects";

// Activity Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* getStakes({ payload }) {
  try {
    const response = yield call(API.getStakes, payload);

    if (response.status == "failure") {
      return yield put(ACTIONS.getStakesFail(response.message));
    }

    yield put(ACTIONS.getStakesSuccess(response));
  } catch (error) {
    yield put(ACTIONS.getStakesFail(error));
  }
}

// function* getStakeStats({ payload }) {
//   try {
//     const response = yield call(API.getStakeStats, payload);

//     if (response.status == "failure") {
//       return yield put(ACTIONS.getStakeStatsFail(response.message));
//     }

//     yield put(ACTIONS.getStakeStatsSuccess(response));
//   } catch (error) {
//     yield put(ACTIONS.getStakeStatsFail(error));
//   }
// }

function* StakesSaga() {
  yield takeEvery(TYPES.GET_STAKES, getStakes);
  // yield takeEvery(TYPES.GET_STAKE_STATS, getStakeStats);
}

export default StakesSaga;
