import { Spinner } from 'react-bootstrap';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const ConfirmModal = ({
    visible,
    onClose,
    onConfirm,
    message = "",
    confirmLoader=false,
    ...args
   }) => {
 

const toggle = () =>{
    onClose && onClose();
}

const onSubmit = () =>{
    onConfirm && onConfirm();
}

  return (
    <div>
      <Modal isOpen={visible} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Confirmation</ModalHeader>
        <ModalBody>
                <p>{message}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit}>
            {confirmLoader && <Spinner animation="border" variant="light" size='sm'/>} Yes
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}


export default ConfirmModal;

