import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Container } from "reactstrap";
import {useSelector, useDispatch} from "react-redux";
import { getContentPage } from "store/actions";
function Termscontent(props) {
  const {contentPage, loading} = useSelector(({content_page}) => content_page);
const dispatch = useDispatch();
  useEffect(()=>{
       dispatch(getContentPage({type : "TERMS_CONDITIONS"}));
  },[]);
  return (
    <section className="terms_conditions">
      <Container fluid>
        <div className="terms_page_content mt-5 px-5">
          <h1>{props.t("term_and_conditions")}</h1>
           <div dangerouslySetInnerHTML={{"__html" : contentPage?.content}}></div>
        </div>
      </Container>
    </section>
  );
}

export default withTranslation()(Termscontent);
