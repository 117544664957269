import {
    ADD_CONTACT_US_FAIL,
    ADD_CONTACT_US_REQUEST,
    ADD_CONTACT_US_SUCCESS
    } from "./actionTypes";
    
    //subscriptions
    
    export const addContactUsRequest = (data,callback) =>{
      return {
        type: ADD_CONTACT_US_REQUEST,
        payload: {data, callback },
      }
    }
    export const addContactUsSuccess = (data) =>{
      return {
        type: ADD_CONTACT_US_SUCCESS,
        payload: data,
      }
    }
    export const addContactUsFail = (error) =>{
      return {
        type: ADD_CONTACT_US_FAIL,
        payload: error,
     }
    }
    