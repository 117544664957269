import {
    GET_USER_BOOKINGS_REQUEST,
    GET_USER_BOOKINGS_SUCCESS,
    GET_USER_BOOKINGS_FAIL,
    GET_OWNER_BOOKINGS_REQUEST,
    GET_OWNER_BOOKINGS_SUCCESS,
    GET_OWNER_BOOKINGS_FAIL,
    GET_BOOKINGS_REQUEST,
    GET_BOOKINGS_SUCCESS,
    GET_BOOKINGS_FAIL,
    GET_BOOKING_DETAILS_REQUEST,
    GET_BOOKINGS_DETAILS_SUCCESS,
    GET_BOOKINGS_DETAILS_FAIL,
  } from "./actionTypes"
  

  const initialState = {
    loading : false,
    error : "",
    userBookingsList : [],
    totalUserBookings : 0,
    ownerBookingsList : [],
    totalOwnerBookings: 0,
    bookingDetails : null
  }
  
  
  const Support = (state = initialState, action) => {
    switch (action.type) {
     
        case GET_USER_BOOKINGS_REQUEST:
          return {
          ...state,
          loading : true
          }
        case GET_USER_BOOKINGS_SUCCESS:
          return {
            ...state,
            loading : false,
            userBookingsList : action.payload.data,
            totalUserBookings : action.payload.totalCount
          }    
      case GET_USER_BOOKINGS_FAIL:
        return {
          ...state,
          loading : false,
          error: action.payload,
        }
        case GET_OWNER_BOOKINGS_REQUEST:
            return {
            ...state,
            loading : true
            }
          case GET_OWNER_BOOKINGS_SUCCESS:
            return {
              ...state,
              loading : false,
              ownerBookingsList : action.payload.data,
              totalOwnerBookings : action.payload.totalCount
            }    
        case GET_OWNER_BOOKINGS_FAIL:
          return {
            ...state,
            loading : false,
            error: action.payload,
          }
        case GET_BOOKINGS_REQUEST:
        return {
        ...state,
          loading: true,
          bookingsList: [],
          totalBookings : 0
        }
        case GET_BOOKINGS_SUCCESS:
        return {
            ...state,
            loading : false,
            bookingsList : action.payload.data,
            totalBookings : action.payload.totalCount
        }    
    case GET_BOOKINGS_FAIL:
        return {
        ...state,
        loading : false,
        error: action.payload,
        }      
        case GET_BOOKING_DETAILS_REQUEST:
          return {
          ...state,
            loading: true,
          bookingDetails : null
          }
      case GET_BOOKINGS_DETAILS_SUCCESS:
          return {
              ...state,
              loading : false,
              bookingDetails : action.payload.data,
          }    
      case GET_BOOKINGS_DETAILS_FAIL:
          return {
          ...state,
          loading : false,
          error: action.payload,
          }      
                                  
    }
    return state
  }
  
  export default Support
  