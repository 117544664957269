import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Alert, Button, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import loginbaner from "assets/images/loginbaner.png";

// actions
import {
addParkingOwnerRequest
} from "store/actions";
import { CountryCodeSelector } from "components/Common/CountryCodeSelector";
import { withTranslation } from "react-i18next";



const Signup = (props) => {
  const [fields,setFields] = useState({
    name : "",
    email : "",
    countryCode : "",
    mobileNumber : "",
    password : "",
    role : "OWNER",
  });

  const {loading} = props;

  const onFieldsChange = (e) =>{
    const {name,value} = e.target;
    setFields({...fields,[name]:value})
  }

  const onSubmit = (e) =>{
    e.preventDefault();
    console.log("fields",fields)
    const payload = {...fields};

    props.addParkingOwnerRequest(payload, ()=>{
         props.history.push("/");
    })
  
  }


  return (
    <>
     <section id="login">
        <div className="container">
          <div className="wraaping_login">
            <div className="form_Side">
              <div className="login_right_banner">
                <img src={loginbaner} alt="" className="img_fluid" />
              </div>

              <Form id="regForm" onSubmit={onSubmit}>
                  <div className="form_layout">
                    <Row>
                      <Col lg={12} md={12} sm={12}>
                        <FormGroup>
                          <Label for="exampleEmail">{props.t("name")}</Label>
                          <Input
                            type="text"
                            name="name"
                            value={fields.name}
                            id="exampleEmail"
                            placeholder=""
                            onChange={(e)=>onFieldsChange(e)}
                          />
                        </FormGroup>
                      </Col>

                      <Col lg={12} md={12} sm={12}>
                        <FormGroup>
                          <Label for="exampleEmail">{props.t("email")}</Label>
                          <Input
                            type="text"
                            name="email"
                            id="exampleEmail"
                            placeholder=""
                            onChange={(e)=>onFieldsChange(e)}
                            value={fields.email}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} md={12} sm={12}>
                        <FormGroup>
                          <Label for="exampleEmail">{props.t("country_code")}</Label>
                            <CountryCodeSelector
                          extraStyleClasses=""
                              selectorName="countryCode"
                              selectedCountryCode={fields.countryCode}
                              onChangeHandler={onFieldsChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={12} md={12} sm={12}>
                        <FormGroup>
                          <Label for="exampleEmail">{props.t("mobile")}</Label>
                          <Input
                            type="text"
                            name="mobileNumber"
                            id="exampleEmail"
                            value={fields.mobileNumber}
                            
                            onChange={(e)=>onFieldsChange(e)}

                          />
                        </FormGroup>
                      </Col>
                     <Col lg={12} md={12} sm={12}>
                        <FormGroup>
                          <Label for="exampleEmail">{props.t("password")}</Label>
                          <Input
                            type="password"
                            name="password"
                            value={fields.password}
                            id="exampleEmail"
                            
                            onChange={(e)=>onFieldsChange(e)}
                          />
                        </FormGroup>
                      </Col>
                      {/* <Col lg={12}>
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              required
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck"
                              name="example1"
                              checked={fields?.policy}
                              onChange={(e) => {
                                handleChange("policy")({ target: { value: !fields?.policy } });
                              }}
                            />

                            <label className="custom-control-label" for="customCheck">
                              I agree all
                              <a href="#" className="colortextlink">
                                Term & Conditions
                              </a>
                            </label>
                          </div>
                        </Col>
                         */}
                      <Col lg={12}> 
                        <Button type="submit" className="themebtn fullwidthbtn">
                              {loading ? 
                                  <Spinner  className="mx-1" size="sm" color="light"/> 
                              : null}
                             {props.t("signup")}
                        </Button>
                      </Col>
                      <Col lg={12}>
                          <div className="reglink">
                            <p>
                              {" "}
                              Already have an account?  {" "}
                              <Link to="/login" className="colortextlink">
                                Log in
                              </Link>
                            </p>
                          </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = ({Login}) => {
  const { loading } = Login;
  return {  loading };
};

export default withRouter(
  connect(mapStateToProps, {
    addParkingOwnerRequest
  })(withTranslation()(Signup))
);
