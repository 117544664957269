import React from 'react'
import { Container } from 'reactstrap'

import Welcomegreet from 'components/Welcomegreet'
import Aboutuscontent from './aboutuscontent'

function Aboutus() {
  return (
    <React.Fragment>
    <div className="page-content">
         <Container fluid>
              <Welcomegreet/>
              <Aboutuscontent/>
         </Container>
    </div>
    </React.Fragment>
  )
}

export default Aboutus